import React, { useEffect, useState } from "react";

// mui-components
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputBase,
  Paper,
  Typography
} from "@mui/material";

// services
import ApiCalls from "../../services/try";

// externals
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

const Mastermenu = ({ steps, activeStep, setActiveStep }) => {
  const [menu, setmenu] = useState([]);
  const [file, setfile] = useState([]);
  const [menucheck, setMenucheck] = useState([]);
  const [userid, setuserid] = useState("");
  const [focus, setfocus] = useState(true);
  const [base, setbase] = useState(true);
  const [doneediting, setdoneediting] = useState(false);
  const [updatedMenucheck, setUpdatedMenucheck] = useState();

  useEffect(() => {
    console.log("going to get mastermenu list...");
    let user = secureLocalStorage.getItem("userid");
    user = user.replace(/['"]+/g, "");

    setTimeout(() => {
      ApiCalls.getMasterMenuList(user).then((response) => {
        console.log(response.data);
        let list = response.data;

        console.log("going to get custom menu list...");
        ApiCalls.getCustomMenuList(user).then((response) => {
          console.log(response.data);
          const selectedmenu = response.data;

          // Initialize the menu array with the checked property
          const updatedMenu = list.map((menu) => {
            const customMenu = selectedmenu.find(
              (custom) => custom.menuuuid === menu.menuuuid
            );
            if (customMenu) {
              return {
                ...menu,
                menutext: customMenu.menuText,
                checked: true, // Set the checked property
              };
            } else {
              return menu;
            }
          });

          setmenu(updatedMenu);

          // Initialize the menucheck array
          const updatedMenucheck = selectedmenu.map((custom) => ({
            userId: user,
            menuuuid: custom.menuuuid,
            menuText: custom.menuText,
            priority: custom.priority,
            file: file,
          }));

          setMenucheck(updatedMenucheck);
        });
      });
    }, 1000);

    console.log(user);
    setuserid(user);
  }, []);

  const editMenu = () => {
    setfocus(false);
    console.log("look check");
    setbase(false);
    console.log(base);
    alert(" Tap the text to edit");
    setdoneediting(true);
  };

  const doneEdit = () => {
    setfocus(true);
    console.log("look check");
    setbase(true);
    setdoneediting(false);
  };

  const handleCheckboxChange = (e, text, priority) => {
    console.log(text);
    console.log(priority);
    console.log(e.target.value);
    console.log(Object.values(file));

    const st = {
      userId: userid,
      menuuuid: e.target.name,
      menuText: text,
      priority: priority,
    };
    if (e.target.checked) {
      setMenucheck((prevMenucheck) => [...prevMenucheck, st]);
    } else {
      swal({
        title: "Are you sure?",
        text: "You want to remove " + e.target.value + " from Menu!!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const updatedMenucheck = menucheck.filter(
            (item) => item.menuuuid !== e.target.name
          );
          setMenucheck(updatedMenucheck);
          setUpdatedMenucheck();
          console.clear(); // Clear the console to remove all previous logs
          console.log("Checkbox unchecked. Updated menucheck:", updatedMenucheck);
        }
      });
    }
  };

  const handleMenucheck = () => { };

  const forwardSubmit = () => {
    console.log("going to submit...");
    secureLocalStorage.setItem("selectedmenu", JSON.stringify(menucheck));

    for (let i = 0; i < file.length; i++) {
      const obj1 = file[i];
      if (obj1.hasOwnProperty("menuText")) {
        const menuicon = obj1.menuicon;
        for (let j = 0; j < menucheck.length; j++) {
          const obj2 = menucheck[j];
          if (obj2.menuText === obj1.menuText) {
            obj2.menuicon = menuicon;
          }
        }
      }
    }

    console.log("if menuicon selected");
    console.log(menucheck);

    const key = "menuicon";

    for (let i = 0; i < menucheck.length; i++) {
      if (!menucheck[i].hasOwnProperty(key)) {
        menucheck[i][key] = "";
      }
    }
    console.log("final result");
    console.log(menucheck);

      ApiCalls.register("ithings/insertCustomMenu", menucheck).then((response) => {
        console.log("response of apicall");
        console.log(response.data);
        if (response.data[0].status.message === "Success") {
          setActiveStep(2);

        }
      });
  };

  const fileInput = (e, menutext) => {
    console.log(e.target.files[0]);
    console.log(menutext);
    if (e.target.files.length === 0) {
      console.log("file removed");
      console.log(e.target.files);
      console.log(menutext);
      var array = [...file];
      const index3 = array.findIndex((item) => item.menutext === menutext);
      console.log("Index3: ", index3);
      if (index3 !== -1) {
        array.splice(index3, 1);
        console.log(JSON.stringify(array));
        setfile(array);
      }
      console.log("Icons after file removed : " + JSON.stringify(array));
    } else {
      console.log("file added");
      const fileExtension = e.target.files[0].name.split(".").at(-1);
      const allowedFileTypes = ["png"];
      if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(
          `File does not support. Files type must be ${allowedFileTypes.join(", ")}`
        );
        return false;
      } else if (e.target.files[0].size > 300000) {
        window.alert("Please upload a file smaller than 300kb");
        return false;
      } else {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        ApiCalls.storeFile("uploadCustomMenuandIcon", formData)
          .then((response) => {
            console.log(response.data);
            if (response.data.message === "File Uploaded Successfully") {
              let filename = e.target.files[0].name;
              setfile([
                ...file,
                {
                  menutext: menutext,
                  menuicon: filename,
                },
              ]);
              console.log("Icons selected: " + JSON.stringify(file));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <div bgcolor="#E8F6EF">
      <div
        style={{
          color: "F5F5F5",
        }}
      >
        {base ? (
          <Button
            sx={{
              fontFamily: "Lato, sans-serif",
              backgroundColor: "#51C4D3",
              Alignitem: "center",
              marginLeft: "20px",
            }}
            onClick={editMenu}
            variant="contained"
            Alignitem="center"
          >
            Change Menu Name
          </Button>
        ) : (
          <Button
            sx={{
              fontFamily: "Lato, sans-serif",
              backgroundColor: "#51C4D3",
              Alignitem: "center",
            }}
            onClick={doneEdit}
            variant="contained"
          >
            Done Editing
          </Button>
        )}

        <Grid container spacing={2}>
          {menu.map((menutext, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Paper
                sx={{
                  backgroundColor: "#F4F6F8",
                  boxShadow: 5,
                  mt: 2,
                  height: 150,
                  maxWidth: 500,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  px: 3,
                  py: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    ml: 2,
                  }}
                >
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        value={menutext.menuText}
                        name={menutext.menuuuid}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            menutext.menuText,
                            menutext.priority
                          )
                        }
                        defaultChecked={menutext.checked}
                      />
                    }
                    label={
                      <InputBase
                        readOnly={focus}
                        name={menutext.menuuuid}
                        sx={
                          base
                            ? {
                              color: "#090A0A",
                            }
                            : {
                              color: "#1976D2",
                              bordercolor: "blue",
                              border: "2px solid #38A3A5",
                              borderRadius: "5px",
                              width: "200px",
                              height: "50px",
                              px: 2,
                            }
                        }
                        autoFocus={base}
                        defaultValue={menutext.menuText}
                        onChange={(e) => {
                          menutext.menuText = e.target.value;
                          handleMenucheck(e, menutext.menuText);
                        }}
                      />
                    }
                  />
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      gutterBottom
                    >
                      Upload Icon(*Optional)
                    </Typography>
                    <input
                      accept="image/*"
                      type="file"
                      onChange={(e) => fileInput(e, menutext.menuText)}
                    />
                  </Box>
                  <Box></Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Container
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "space-around",
          }}
        >
          <Button
            sx={{ marginLeft: "30%" }}
            variant="contained"
            color="primary"
            onClick={() => setActiveStep(0)}
          >
            Prev
          </Button>
          <Button
            disabled={doneediting}
            sx={{ marginLeft: "20px" }}
            variant="contained"
            color="primary"

            onClick={() => {
              // setActiveStep(2);
              forwardSubmit();
            }}
          >
            Next
          </Button>
        </Container>
      </div>
    </div>
  );
};

export default Mastermenu;

