import React, { useEffect, useState } from 'react';

//mui-components
import { Close, Delete, Done, Edit } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Container,
    Dialog,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    ListItemIcon,
    MenuItem,
    Paper,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from "../../services/try";

//components
import Iconify from '../../components/iconify/Iconify';

//externals
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";
//--------------------------------------------------------------------------------------------------------------------

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const units = [
    { value: '', label: 'none' },
    { value: 'cm', label: 'cm' },
    { value: 'degree', label: 'degree' },
    { value: 'fahrenheit', label: 'fahrenheit' },
    { value: 'Kelvin', label: 'kelvin' },
    { value: '%', label: '%' },
    { value: 'mmHg', label: 'mmHg' },
    { value: 'kPa', label: 'kilopascals' },
    { value: 'mmol/L', label: 'millimolar' },
    { value: 'mg/dL', label: 'mg/d' },
    { value: 'ms', label: 'milliseconds' },
]

//--------------------------------------------------------------------------------------------------------------------



const ParameterList = ({ handleNext, handleBack, activeStep, steps }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [defaultParameters, setDefaultParameters] = useState([]);
    const [newUserParameters, setNewUserParameters] = useState([]);
    const [selectedParameters, setSelectedParameters] = useState([]);
    const [checkedParameters, setCheckedParameters] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [paramName, setParamName] = useState('');
    const [type, setType] = useState('');
    const [frequency, setFrequency] = useState('');
    const [measureType, setMeasureType] = useState('');
    const [valueType, setValueType] = useState('');
    const [unit, setUnit] = useState('');
    const [typical, setTypical] = useState('');
    const [tags, setTags] = useState('');
    const [rangeName, setRangeName] = useState('');
    const [rangeType, setRangeType] = useState('');
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const [thresholdHigh, setThresholdHigh] = useState('');
    const [thresholdLow, setThresholdLow] = useState('');
    const [paramIdtoEdit, setParamIdtoEdit] = useState('');

    const [paramNameError, setParamNameError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [valueTypeError, setValueTypeError] = useState(false);
    const [rangeNameError, setRangeNameError] = useState(false);
    const [thresholdHighError, setThresholdHighError] = useState(false);
    const [thresholdlowError, setThresholdLowError] = useState(false);

    const [parameterTab, setParameterTab] = useState(0);

    const [loadingDefaultParameters, setLoadingDefaultParameters] = useState(true);
    const [loadingNewUserParameters, setLoadingNewUserParameters] = useState(true);
    const [openAddNewParameter, setOpenAddNewParameter] = useState(false);
    const [selectedMeasurement, setSelectedMeasurement] = useState(false);
    const [selectedBetween, setSelectedBetween] = useState(false);
    const [openParamEdit, setOpenParamEdit] = useState(false);

    const loadDefaultParameters = () => {

        setLoadingDefaultParameters(true);
        ApiCalls.getdetails("getdefaultParameters")
            .then((response) => {
                console.log(response.data);
                if (response.data.parameters != undefined) {
                    setDefaultParameters(response.data.parameters);
                    setLoadingDefaultParameters(false);
                } else {
                    setDefaultParameters([]);
                    setLoadingDefaultParameters(false);
                }
            }).catch((error) => {
                console.log(error);
                setDefaultParameters([]);
                setLoadingDefaultParameters(false);
            })
    };

    const loadNewUserParameters = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("UUID");
            physicianId = "0";
        }
        ApiCalls.getdetails("tenant/" + tenantId + "/company/" + companyId + "/getnewParameters")
            .then((response) => {
                console.log(response.data);
                if (response.data.parameters != undefined) {
                    setNewUserParameters(response.data.parameters);
                    setLoadingNewUserParameters(false);
                } else {
                    setNewUserParameters([]);
                    setLoadingNewUserParameters(false);
                }
            }).catch((error) => {
                console.log(error);
                setNewUserParameters([]);
                setLoadingNewUserParameters(false);
            })
    };

    useEffect(() => {
        loadDefaultParameters();
        loadNewUserParameters();

        const selectedParameters = JSON.parse(secureLocalStorage.getItem("selectedParameters")) || [];
        setSelectedParameters(selectedParameters);

    }, [])

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const handleChangeParameterTab = (event, newValue) => {
        setParameterTab(newValue);
    };

    const filteredDefaultParameters = defaultParameters.filter((param) =>
        param.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredNewUserParameters = newUserParameters.filter((param) =>
        param.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSelectParameters = (event) => {
        const checkboxValue = event.target.value;

        if (event.target.checked) {
            // Add the checked value's paramId and name to the array
            setSelectedParameters([...selectedParameters, { paramId: checkboxValue, name: event.target.name }]);
        } else {
            // Remove the unchecked value's paramId and name from the array
            setSelectedParameters(selectedParameters.filter((param) => param.paramId !== checkboxValue));
        }

        console.log(selectedParameters)
    };

    const handleClickSelectParameters = () => {
        if (selectedParameters.length > 0) {
            secureLocalStorage.setItem("selectedParameters", JSON.stringify(selectedParameters));
            handleNext();
        } else {
            swal("Select Parameters", {
                icon: "info",
            })
        }
    }

    const handleOpenAddNewParameter = () => {
        setOpenAddNewParameter(true);
    }

    const handleCloseAddNewParameter = () => {
        setOpenAddNewParameter(false);
        setParamName('');
        setType('');
        setFrequency('');
        setMeasureType('');
        setValueType('');
        setUnit('');
        setTypical('');
        setUnit('');
        setTags('');
        setRangeName('');
        setRangeType('');
        setMin('');
        setMax('');
        setThresholdLow('');
        setThresholdHigh('');
        setParamNameError(false);
        setTypeError(false);
        setValueTypeError(false);
        setRangeNameError(false);
        setThresholdHighError(false);
        setThresholdLowError(false);
    }

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'name':
                    setParamNameError(true);
                    break;
                case 'type':
                    setTypeError(true);
                    break;
                case 'valueType':
                    setValueTypeError(true);
                    break;
                case 'rangeName':
                    setRangeNameError(true);
                    break;
                case 'thresholdhigh':
                    setThresholdHighError(true)
                    break;
                case 'thresholdlow':
                    setThresholdLowError(true)
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'name':
                setParamNameError(false);
                setParamName(event.target.value);
                break;
            case 'type':
                setTypeError(false);
                setType(event.target.value);
                event.target.value == 2 || event.target.value == 3 ? setSelectedMeasurement(true) : setSelectedMeasurement(false);
                break;
            case 'measureType':
                setMeasureType(event.target.value);
                break;
            case 'frequency':
                setFrequency(event.target.value);
                break;
            case 'valueType':
                setValueTypeError(false);
                setValueType(event.target.value);
                break;
            case 'unit':
                setUnit(event.target.value);
                break;
            case 'typical':
                setTypical(event.target.value);
                break;
            case 'tags':
                setTags(event.target.value);
                break;
            case 'rangeName':
                setRangeNameError(false);
                setRangeName(event.target.value);
                break;
            case 'rangeType':
                setRangeType(event.target.value);
                event.target.value === "between" ? setSelectedBetween(true) : setSelectedBetween(false);
                break;
            case 'min':
                setMin(event.target.value);
                break;
            case 'max':
                setMax(event.target.value);
                break;
            case 'thresholdhigh':
                setThresholdHighError(false)
                setThresholdHigh(event.target.value);
                break;
            case 'thresholdlow':
                setThresholdLowError(false)
                setThresholdLow(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleAddNewParameter = () => {
        // Collect validation errors
        const errors = {};

        if (!paramName) {
            errors.paramNameError = true;
        }
        if (!type) {
            errors.typeError = true;
        }
        if (!valueType) {
            errors.valueTypeError = true;
        }
        if (!rangeName) {
            errors.rangeNameError = true;
        }
        if (!thresholdHigh) {
            errors.thresholdHighError = true;
        }
        if (!thresholdLow) {
            errors.thresholdLowError = true;
        }

        // Display errors if any
        if (Object.keys(errors).length > 0) {
            // Set all errors at once
            setParamNameError(errors.paramNameError || false);
            setTypeError(errors.typeError || false);
            setValueTypeError(errors.valueTypeError || false);
            setRangeNameError(errors.rangeNameError || false);
            setThresholdHighError(errors.thresholdHighError || false);
            setThresholdLowError(errors.thresholdLowError || false);
            return; // Stop further execution
        } else {
            var data = {
                name: paramName,
                type: type,
                measureType: measureType,
                frequency: frequency,
                valueType: valueType,
                unit: unit ? unit : 'none',
                typical: typical,
                tags: tags ? tags : "none",
                range: [
                    {
                        rangeName: rangeName,
                        rangeType: rangeType,
                        min: min,
                        max: max,
                        thresholdhigh: thresholdHigh,
                        thresholdlow: thresholdLow,
                    }
                ]
            }

            console.log("Final Data");
            console.log(data)

            var tenantId = secureLocalStorage.getItem("tenantId");
            var companyId = secureLocalStorage.getItem("compId");
            var physicianId = "";
            var rolename = secureLocalStorage.getItem("rolename");
            if (rolename === "Tenant") {
                tenantId = secureLocalStorage.getItem("UUID");
                companyId = "0";
                physicianId = "0";
            } else if (rolename === "Company") {
                tenantId = secureLocalStorage.getItem("tenantId");
                companyId = secureLocalStorage.getItem("UUID");
                physicianId = "0";
            }
            ApiCalls.register("tenant/" + tenantId + "/company/" + companyId + "/parameters", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Parameter Successfully Registered", {
                            icon: "success",
                        });
                        console.log(response.data.data.uuid);
                        secureLocalStorage.setItem('paramIdSaved', response.data.data.uuid);
                        handleCloseAddNewParameter();
                        loadNewUserParameters();
                    }
                }).catch((err) => {
                    console.log(err);
                    console.log(err.message);
                    if (err.message === "Request failed with status code 409") {
                        swal("Parameter Already Existing", {
                            icon: "info",
                        });
                    }
                })

        }
    }

    const handleDeleteParameter = (paramId) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {

                ApiCalls.delete("parameters/" + paramId)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            swal("Deleted Successfully!", {
                                icon: "success",
                            });
                            loadDefaultParameters();
                            loadNewUserParameters();
                        }
                    });
            }
        });
    }

    const handleEditParameter = (paramId) => {
        setParamIdtoEdit(paramId)
        ApiCalls.getdetails(`parameter/${paramId}`)
            .then((response) => {
                console.log(response.data);
                const paramData = response.data.parameters[0];
                const rangeData = paramData.range[0];
                setParamName(paramData.name);
                setType(paramData.type);
                setFrequency(paramData.frequency);
                setMeasureType(paramData.measureType);
                setValueType(paramData.valueType);
                setUnit(paramData.unit);
                setTypical(paramData.typical);
                setUnit(paramData.unit);
                setTags(paramData.tags);
                setRangeName(rangeData.rangeName);
                if (rangeData.rangeType === "between") {
                    setSelectedBetween(true);
                } else {
                    setSelectedBetween(false);
                }
                setRangeType(rangeData.rangeType);
                setMin(rangeData.min);
                setMax(rangeData.max);
            })
        setOpenParamEdit(true);
    }

    const handleCloseEditParameter = () => {
        setOpenParamEdit(false);
        setParamName('');
        setType('');
        setFrequency('');
        setMeasureType('');
        setValueType('');
        setUnit('');
        setTypical('');
        setUnit('');
        setTags('');
        setRangeName('');
        setRangeType('');
        setMin('');
        setMax('');
    }

    const handleUpdateParameter = () => {

        if (!paramName) {
            setParamNameError(true);
        } else if (!type) {
            setTypeError(true);
        } else if (!valueType) {
            setValueTypeError(true);
        } else if (!rangeName) {
            setRangeNameError(true);
        } else if (!thresholdHigh) {
            setThresholdHighError(true);
        } else if (!thresholdLow) {
            setThresholdLowError(true);
        } else {
            var data = {
                name: paramName,
                type: type,
                measureType: measureType,
                frequency: frequency,
                valueType: valueType,
                unit: unit ? unit : 'none',
                typical: typical == undefined ? "" : typical,
                tags: tags ? tags : "none",
                range: [
                    {
                        rangeName: rangeName == undefined ? "" : rangeName,
                        rangeType: rangeType,
                        min: min,
                        max: max,
                        thresholdhigh: thresholdHigh,
                        thresholdlow: thresholdLow,
                    }
                ]
            }

            console.log("Final Data");
            console.log(data)

            ApiCalls.edit(`parameters/${paramIdtoEdit}`, data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Parameter Successfully Updated", {
                            icon: "success",
                        });
                        handleCloseEditParameter();
                        loadNewUserParameters();
                        loadDefaultParameters();
                    }
                }).catch((err) => {
                    console.log(err);
                    console.log(err.message);
                })

        }
    }

    const formatCreateLabel = (inputValue) => `Create value type "${inputValue}"`;

    return (
        <Container sx={{ marginTop: 5, minWidth: 1000 }}>
            <Card>
                <CardHeader
                    title={<Tabs
                        value={parameterTab}
                        onChange={handleChangeParameterTab}
                        onMouseDown={handleMouseDown}
                        aria-label="icon label tabs example"
                    >
                        <Tab
                            icon={<Iconify icon={'material-symbols:list-alt'} />}
                            label="Existing Parameters"
                            sx={{
                                fontSize: 18,
                            }}
                        />
                        <Tab
                            icon={<Iconify icon={'material-symbols:tab-new-right-rounded'} />}
                            label="New Parameters"
                            wrapped
                            sx={{
                                fontSize: 18,
                            }}
                        />
                    </Tabs>}
                    action={
                        <TextField
                            label="Search Parameter"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    }
                />
                <CardContent sx={{ overflowY: "scroll", padding: 0, maxHeight: 400 }}>
                    <TabPanel value={parameterTab} index={0}>
                        {
                            loadingDefaultParameters ?
                                <center>
                                    <CircularProgress />
                                </center> :
                                filteredDefaultParameters.length > 0 ?
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 12, md: 12 }}>
                                        {filteredDefaultParameters.map((parameter) => (
                                            <Grid item xs={2} sm={4} md={4} key={parameter.paramId}>
                                                <FormControlLabel
                                                    key={parameter.paramId}
                                                    control={<Checkbox
                                                        value={parameter.paramId}
                                                        name={parameter.name}
                                                        onChange={handleSelectParameters}
                                                        checked={selectedParameters.some((param) => param.paramId === parameter.paramId)}
                                                    />}
                                                    label={
                                                        <Card sx={{
                                                            backgroundColor: "#F4F6F8",
                                                            boxShadow: 2
                                                        }}>
                                                            <CardHeader
                                                                title={
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                            {parameter.name.toUpperCase()}
                                                                        </Typography>
                                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                <IconButton color='primary' size='small' onMouseDown={handleMouseDown} onClick={() => handleEditParameter(parameter.paramId)}>
                                                                                    <Edit fontSize="inherit" />
                                                                                </IconButton>
                                                                                <IconButton color='error' size='small' onMouseDown={handleMouseDown} onClick={() => handleDeleteParameter(parameter.paramId)}>
                                                                                    <Delete fontSize="inherit" />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                } />
                                                        </Card>
                                                    }
                                                />
                                            </Grid>

                                        ))}
                                    </Grid>
                                    : <center>
                                        No Parameter Found
                                    </center>
                        }
                    </TabPanel>

                    <TabPanel value={parameterTab} index={1}>
                        {
                            loadingNewUserParameters ?
                                <center>
                                    <CircularProgress />
                                </center> :
                                filteredNewUserParameters.length > 0 ?
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 12, md: 12 }}>
                                        {filteredNewUserParameters.map((parameter) => (
                                            <Grid item xs={2} sm={4} md={4} key={parameter.paramId}>
                                                <FormControlLabel
                                                    key={parameter.paramId}
                                                    control={<Checkbox
                                                        value={parameter.paramId}
                                                        name={parameter.name}
                                                        onChange={handleSelectParameters}
                                                        checked={selectedParameters.some((param) => param.paramId === parameter.paramId)}
                                                    />}
                                                    label={
                                                        <Card sx={{
                                                            backgroundColor: "#F4F6F8",
                                                            boxShadow: 2,
                                                        }}>
                                                            <CardHeader
                                                                title={
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                            {parameter.name.toUpperCase()}
                                                                        </Typography>
                                                                        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                            <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                <IconButton color='primary' size='small' onMouseDown={handleMouseDown} onClick={() => handleEditParameter(parameter.paramId)}>
                                                                                    <Edit fontSize="inherit" />
                                                                                </IconButton>
                                                                                <IconButton color='error' size='small' onMouseDown={handleMouseDown} onClick={() => handleDeleteParameter(parameter.paramId)}>
                                                                                    <Delete fontSize="inherit" />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                } />
                                                        </Card>
                                                    }
                                                />
                                            </Grid>

                                        ))}
                                    </Grid>
                                    : <center>
                                        No Parameter Found
                                    </center>
                        }
                    </TabPanel>
                </CardContent>
                <CardActions>
                    <Button
                        fullWidth
                        color="error"
                        size="small"
                        type="submit"
                        variant="outlined"
                        onMouseDown={handleMouseDown}
                        onClick={handleBack}
                        sx={{
                            borderRadius: "12px",
                        }}
                        startIcon={
                            <Iconify icon={'ic:twotone-arrow-left'} />
                        }
                    >
                        Previous
                    </Button>
                    <Button
                        fullWidth
                        color="primary"
                        size="small"
                        type="submit"
                        variant="outlined"
                        onMouseDown={handleMouseDown}
                        onClick={handleOpenAddNewParameter}
                        sx={{
                            borderRadius: "12px",
                        }}
                        startIcon={
                            <Iconify icon={'ic:round-new-label'} />
                        }
                    >
                        Add New
                    </Button>
                    <Button
                        fullWidth
                        color="primary"
                        size="small"
                        type="submit"
                        variant="outlined"
                        onMouseDown={handleMouseDown}
                        onClick={handleClickSelectParameters}
                        sx={{
                            borderRadius: "12px",
                        }}
                        endIcon={
                            <Iconify icon={'ic:twotone-arrow-right'} />
                        }
                    >
                        Next
                    </Button>
                </CardActions>
            </Card>

            <div>
                <Dialog open={openAddNewParameter} onClose={handleCloseAddNewParameter} fullScreen={fullScreen}>
                    <Card sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        '@media screen and (max-height: 400px)': {
                            height: 'auto',
                        },
                    }}>
                        <CardHeader
                            title={<Typography variant="h4" gutterBottom>
                                Add Parameter
                            </Typography>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseAddNewParameter}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent sx={{
                            overflowY: "scroll"
                        }} >
                            <Box sx={{ width: 1 }}>
                                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                    <Box gridColumn="span 12">
                                        <Item>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <TextField
                                                    name="name"
                                                    label={
                                                        <span>
                                                            Parameter Name{' '}
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </span>
                                                    }
                                                    value={paramName}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={paramNameError}
                                                    helperText={paramNameError && "Parameter Name is required"}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        <span>
                                                            Type{' '}
                                                            <span style={{ color: 'red' }}>
                                                                *
                                                            </span>
                                                        </span>
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="type"
                                                        value={type}
                                                        label={
                                                            <span>
                                                                Type{' '}
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </span>
                                                        }
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={typeError}
                                                    >
                                                        <MenuItem value={1}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:heart-vitals'} />
                                                            </ListItemIcon>
                                                            Measurement
                                                        </MenuItem>
                                                        <MenuItem value={2}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:call-to-action'} />
                                                            </ListItemIcon>
                                                            Action
                                                        </MenuItem>
                                                        <MenuItem value={3}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'clarity:file-settings-solid'} />
                                                            </ListItemIcon>
                                                            Settings
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText error={typeError} style={{ color: typeError ? 'red' : '' }}>
                                                        {typeError && "Select a type"}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2, display: selectedMeasurement ? "none" : "" }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Measurement Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="measureType"
                                                        label="Measurement Type"
                                                        value={measureType}
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value={1}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:vitals'} />
                                                            </ListItemIcon>
                                                            Stream
                                                        </MenuItem>
                                                        <MenuItem value={2}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'fluent-emoji-high-contrast:input-numbers'} />
                                                            </ListItemIcon>
                                                            Value
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    name="frequency"
                                                    label="Frequency"
                                                    value={frequency}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        <span>
                                                            Value Type{' '}
                                                            <span style={{ color: 'red' }}>
                                                                *
                                                            </span>
                                                        </span>
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="valueType"
                                                        value={valueType}
                                                        label={
                                                            <span>
                                                                Value Type{' '}
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </span>
                                                        }
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={valueTypeError}
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value="1">
                                                            <ListItemIcon>
                                                                <Iconify icon={'carbon:string-integer'} />
                                                            </ListItemIcon>
                                                            Integer
                                                        </MenuItem>
                                                        <MenuItem value="2">
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:decimal-increase'} />
                                                            </ListItemIcon>
                                                            Float
                                                        </MenuItem>
                                                        <MenuItem value="3">
                                                            <ListItemIcon>
                                                                <Iconify icon={'carbon:string-text'} />
                                                            </ListItemIcon>String
                                                        </MenuItem>
                                                        <MenuItem value="4">
                                                            <ListItemIcon>
                                                                <Iconify icon={'heroicons:variable'} />
                                                            </ListItemIcon>Blob
                                                        </MenuItem>
                                                        <MenuItem value="5">
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:file'} />
                                                            </ListItemIcon>File
                                                        </MenuItem>
                                                        <MenuItem value="6">
                                                            <ListItemIcon>
                                                                <Iconify icon={'material-symbols:image-rounded'} />
                                                            </ListItemIcon>Image
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText error={valueTypeError} style={{ color: valueTypeError ? 'red' : '' }}>
                                                        {valueTypeError && "Select the value type"}
                                                    </FormHelperText>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="unit"
                                                        value={unit}
                                                        onChange={handleChange}
                                                        label="Unit"
                                                    >
                                                        {units.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <TextField
                                                    name="typical"
                                                    label="Typical"
                                                    value={typical}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                                <TextField
                                                    name="tags"
                                                    label="Tags"
                                                    value={tags}
                                                    onChange={handleChange}
                                                    placeholder="#parameter"
                                                    fullWidth
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <TextField
                                                    name="rangeName"
                                                    label={
                                                        <span>
                                                            Range Name{' '}
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </span>
                                                    }
                                                    value={rangeName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={rangeNameError}
                                                    helperText={rangeNameError && "Range Name is required"}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Range Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="rangeType"
                                                        value={rangeType}
                                                        onChange={handleChange}
                                                        label="Range Type"
                                                    >
                                                        <MenuItem value="" default>
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value="between">
                                                            <ListItemIcon>
                                                                <Iconify icon={'fluent:arrow-between-down-20-filled'} />
                                                            </ListItemIcon>
                                                            Between
                                                        </MenuItem>
                                                        <MenuItem value="lessthan">
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:code-less-than'} />
                                                            </ListItemIcon>
                                                            Less than
                                                        </MenuItem>
                                                        <MenuItem value="greaterthan">
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:code-greater-than'} />
                                                            </ListItemIcon>
                                                            Greater than
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2, display: selectedBetween ? "" : "none" }} >
                                                <TextField
                                                    name="min"
                                                    type="number"
                                                    label="Min Value"
                                                    value={min}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                                <TextField
                                                    name="max"
                                                    type="number"
                                                    label="Max Value"
                                                    value={max}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2, display: selectedBetween ? "none" : "" }}>
                                                <TextField
                                                    name="max"
                                                    type="number"
                                                    label="Value"
                                                    value={max}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <TextField
                                                    name="thresholdhigh"
                                                    type="number"
                                                    label={
                                                        <span>
                                                            Threshold High Value{' '}
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </span>
                                                    }
                                                    value={thresholdHigh}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={thresholdHighError}
                                                    helperText={thresholdHighError && "Threshold High Value is required"}
                                                />
                                                <TextField
                                                    name="thresholdlow"
                                                    type="number"
                                                    label={
                                                        <span>
                                                            Threshold Low Value{' '}
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </span>
                                                    }
                                                    value={thresholdLow}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={thresholdlowError}
                                                    helperText={thresholdlowError && "Threshold Low Value is required"}
                                                />
                                            </Stack>
                                        </Item>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                fullWidth
                                startIcon={<Close />}
                                onMouseDown={handleMouseDown}
                                onClick={handleCloseAddNewParameter}
                            >Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                fullWidth
                                startIcon={<Done />}
                                onMouseDown={handleMouseDown}
                                onClick={handleAddNewParameter}
                            >Submit
                            </Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openParamEdit} onClose={handleCloseEditParameter} fullScreen={fullScreen}>
                    <Card sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        '@media screen and (max-height: 400px)': {
                            height: 'auto',
                        },
                    }}>
                        <CardHeader
                            title={<Typography variant="h4" gutterBottom>
                                Update Parameter
                            </Typography>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseEditParameter}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent sx={{
                            overflowY: "scroll"
                        }} >
                            <Box sx={{ width: 1 }}>
                                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                    <Box gridColumn="span 12">
                                        <Item>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <TextField
                                                    name="name"
                                                    label={
                                                        <span>
                                                            Parameter Name{' '}
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </span>
                                                    }
                                                    value={paramName}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={paramNameError}
                                                    helperText={paramNameError && "Parameter Name is required"}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        <span>
                                                            Type{' '}
                                                            <span style={{ color: 'red' }}>
                                                                *
                                                            </span>
                                                        </span>
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="type"
                                                        value={type}
                                                        label={
                                                            <span>
                                                                Type{' '}
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </span>
                                                        }
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={typeError}
                                                    >
                                                        <MenuItem value={1}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:heart-vitals'} />
                                                            </ListItemIcon>
                                                            Measurement
                                                        </MenuItem>
                                                        <MenuItem value={2}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:call-to-action'} />
                                                            </ListItemIcon>
                                                            Action
                                                        </MenuItem>
                                                        <MenuItem value={3}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'clarity:file-settings-solid'} />
                                                            </ListItemIcon>
                                                            Settings
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText error={typeError} style={{ color: typeError ? 'red' : '' }}>
                                                        {typeError && "Select a type"}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2, display: selectedMeasurement ? "none" : "" }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Measurement Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="measureType"
                                                        label="Measurement Type"
                                                        value={measureType}
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value={1}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:vitals'} />
                                                            </ListItemIcon>
                                                            Stream
                                                        </MenuItem>
                                                        <MenuItem value={2}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'fluent-emoji-high-contrast:input-numbers'} />
                                                            </ListItemIcon>
                                                            Value
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    name="frequency"
                                                    label="Frequency"
                                                    value={frequency}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        <span>
                                                            Value Type{' '}
                                                            <span style={{ color: 'red' }}>
                                                                *
                                                            </span>
                                                        </span>
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="valueType"
                                                        value={valueType}
                                                        label={
                                                            <span>
                                                                Value Type{' '}
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </span>
                                                        }
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={valueTypeError}
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value="1">
                                                            <ListItemIcon>
                                                                <Iconify icon={'carbon:string-integer'} />
                                                            </ListItemIcon>
                                                            Integer
                                                        </MenuItem>
                                                        <MenuItem value="2">
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:decimal-increase'} />
                                                            </ListItemIcon>
                                                            Float
                                                        </MenuItem>
                                                        <MenuItem value="3">
                                                            <ListItemIcon>
                                                                <Iconify icon={'carbon:string-text'} />
                                                            </ListItemIcon>String
                                                        </MenuItem>
                                                        <MenuItem value="4">
                                                            <ListItemIcon>
                                                                <Iconify icon={'heroicons:variable'} />
                                                            </ListItemIcon>Blob
                                                        </MenuItem>
                                                        <MenuItem value="5">
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:file'} />
                                                            </ListItemIcon>File
                                                        </MenuItem>
                                                        <MenuItem value="6">
                                                            <ListItemIcon>
                                                                <Iconify icon={'material-symbols:image-rounded'} />
                                                            </ListItemIcon>Image
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText error={valueTypeError} style={{ color: valueTypeError ? 'red' : '' }}>
                                                        {valueTypeError && "Select the value type"}
                                                    </FormHelperText>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="unit"
                                                        value={unit}
                                                        onChange={handleChange}
                                                        label="Unit"
                                                    >
                                                        {units.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <TextField
                                                    name="typical"
                                                    label="Typical"
                                                    value={typical}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                                <TextField
                                                    name="tags"
                                                    label="Tags"
                                                    value={tags}
                                                    onChange={handleChange}
                                                    placeholder="#parameter"
                                                    fullWidth
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <TextField
                                                    name="rangeName"
                                                    label={
                                                        <span>
                                                            Range Name{' '}
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </span>
                                                    }
                                                    value={rangeName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={rangeNameError}
                                                    helperText={rangeNameError && "Range Name is required"}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Range Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="rangeType"
                                                        value={rangeType}
                                                        onChange={handleChange}
                                                        label="Range Type"
                                                    >
                                                        <MenuItem value="none" default>
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value="between">
                                                            <ListItemIcon>
                                                                <Iconify icon={'fluent:arrow-between-down-20-filled'} />
                                                            </ListItemIcon>
                                                            Between
                                                        </MenuItem>
                                                        <MenuItem value="lessthan">
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:code-less-than'} />
                                                            </ListItemIcon>
                                                            Less than
                                                        </MenuItem>
                                                        <MenuItem value="greaterthan">
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:code-greater-than'} />
                                                            </ListItemIcon>
                                                            Greater than
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2, display: selectedBetween ? "" : "none" }} >
                                                <TextField
                                                    name="min"
                                                    type="number"
                                                    label="Min Value"
                                                    value={min}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                                <TextField
                                                    name="max"
                                                    type="number"
                                                    label="Max Value"
                                                    value={max}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2, display: selectedBetween ? "none" : "" }}>
                                                <TextField
                                                    name="max"
                                                    type="number"
                                                    label="Value"
                                                    value={max}
                                                    onChange={handleChange}
                                                    fullWidth
                                                />
                                            </Stack>
                                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <TextField
                                                    name="thresholdhigh"
                                                    type="number"
                                                    label={
                                                        <span>
                                                            Threshold High Value{' '}
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </span>
                                                    }
                                                    value={thresholdHigh}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={thresholdHighError}
                                                    helperText={thresholdHighError && "Threshold High Value is required"}
                                                />
                                                <TextField
                                                    name="thresholdlow"
                                                    type="number"
                                                    label={
                                                        <span>
                                                            Threshold Low Value{' '}
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </span>
                                                    }
                                                    value={thresholdLow}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={thresholdlowError}
                                                    helperText={thresholdlowError && "Threshold Low Value is required"}
                                                />
                                            </Stack>
                                        </Item>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                fullWidth
                                startIcon={<Close />}
                                onMouseDown={handleMouseDown}
                                onClick={handleCloseEditParameter}
                            >Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                fullWidth
                                startIcon={<Done />}
                                onMouseDown={handleMouseDown}
                                onClick={handleUpdateParameter}
                            >Update
                            </Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

        </Container >
    )
}

export default ParameterList
