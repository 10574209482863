import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import {
    Delete,
    EdgesensorHighTwoTone,
    Edit,
    Home
} from '@mui/icons-material';
import {
    Avatar,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//internals

//externals
import { Icon } from '@iconify/react/dist/iconify.js';
import secureLocalStorage from "react-secure-storage";
import swal from 'sweetalert';

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

// ----------------------------------------------------------------------

const ManageSensor = () => {

    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    const [sensors, setSensors] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [viewMode, setViewMode] = useState('list');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const loadSensorList = () => {
        setLoading(true);
        var tenantId = secureLocalStorage.getItem("tenantId");
        ApiCalls.getdetails("tenantid/" + tenantId + "/listallsensors")
            .then((response) => {
                console.log(response.data);
                if (response.data.sensor != undefined) {
                    setSensors(response.data.sensor);
                    setLoading(false)
                } else {
                    setSensors([]);
                    setLoading(false);
                }
            }).catch((error) => {
                console.log(error);
                setSensors([]);
                setLoading(false);
            })
    }

    useEffect(() => {
        loadSensorList();
    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleChangeViewMode = (event, newViewMode) => {
        if (newViewMode !== null) {
            setViewMode(newViewMode);
        }
    };

    // const filteredSensors = sensors.filter((sensor) =>
    //     sensor.sensormacid.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     sensor.sensorName.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredSensors = sensors.filter((sensor) =>
        sensor.sensormacid.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sensor.sensorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sensor.sensorType.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDeleteSensor = (sensorId) => {
        console.log(sensorId)
        ApiCalls.getdetails("sensor/" + sensorId + "/isSensorConnected")
            .then((response) => {
                console.log(response.data);
                if (response.data == true) {
                    swal({
                        title: "Cannot Delete",
                        text: `This Sensor cannot be deleted at the moment as they are currently connected.`,
                        icon: "warning",
                        buttons: {
                            ok: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "btn-danger",
                                closeModal: true,
                            },
                        },
                        dangerMode: true,
                    });
                } else {
                    swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                        .then((willDelete) => {
                            if (willDelete) {
                                ApiCalls.delete("sensor/" + sensorId + "/deletesensor")
                                    .then((response) => {
                                        console.log(response.data);
                                        if (response.data.status.message === "Success") {
                                            swal("Deleted Successfully!", {
                                                icon: "success",
                                            });
                                            loadSensorList();
                                        }
                                    })
                            }
                        });
                }
            })

    }

    const handleEditSensor = (sensor) => {
        console.log(sensor.sensoruuId);
        secureLocalStorage.setItem("sensorId", sensor.sensoruuId)
        setRedirectToEdit(true);
    }

    const handleScanButtonClick = () => {
        // Open the popup when the "Scan" button is clicked
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        // Close the popup when needed (e.g., when the user clicks outside the popup)
        setOpenPopup(false);
    };

    if (redirectToEdit) {
        return (<Redirect to={'/editsensor'} />)
    }

    if (redirectToForm) {
        return (<Redirect to={'/registersensor'} />)
    }


    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>


                        <StyledBreadcrumb label="Manage Sensor" />
                    </Breadcrumbs>
                    <Button sx={{
                        display: secureLocalStorage.getItem("rolename") === "Tenant" ? "" : "none"
                    }}
                        variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setRedirectToForm(true)}>
                        New Sensor
                    </Button>
                </Stack>
                <Card>
                    <CardHeader
                        title={
                            <Typography variant="h4" gutterBottom>
                                Sensor List
                            </Typography>
                        }
                        action={
                            <Stack direction="row" spacing={2} >
                                <TextField
                                    label="Search Sensor"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <ToggleButtonGroup
                                    size="small"
                                    value={viewMode}
                                    exclusive
                                    onMouseDown={handleMouseDown}
                                    onChange={handleChangeViewMode}
                                    aria-label="view mode"
                                >
                                    <Tooltip title="List View">
                                        <ToggleButton value="list" aria-label="list">
                                            <Icon icon={'ion:list'} width="20" />
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title="Grid View">
                                        <ToggleButton value="grid" aria-label="grid">
                                            <Icon icon={'material-symbols:grid-view-rounded'} width="20" />
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            </Stack>
                        }
                    />
                    <CardContent>
                        {loading ? (
                            <center><CircularProgress /></center>
                        ) : filteredSensors.length > 0 ? (

                            viewMode === 'list' ? <TableContainer TableContainer component={Paper}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Sensor Name</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Sensor Mac Id</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Sensor Type</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Registered Date</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredSensors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sensor) => (
                                            <TableRow key={sensor.sensoruuId} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f4f4f4' } }}>
                                                <TableCell sx={{ textAlign: 'center' }}>{sensor.sensorName}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{sensor.sensormacid}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{sensor.sensorType}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{sensor.createdOn.split(' ')[0]}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    <IconButton color="primary" size="small" onClick={() => handleEditSensor(sensor)}>
                                                        <Edit fontSize="small" />
                                                    </IconButton>
                                                    <IconButton color="error" size="small" onClick={() => handleDeleteSensor(sensor.sensoruuId)}>
                                                        <Delete fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    component="div"
                                    count={sensors.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                        '& .MuiTablePagination-toolbar': {
                                            alignItems: 'center',
                                        },
                                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                                            display: 'flex',
                                            alignItems: 'center',
                                        },
                                        '& .MuiButtonBase-root': {
                                            outline: 'none',
                                            '&:focus': {
                                                outline: 'none',
                                                boxShadow: 'none',
                                            },
                                        },
                                    }}
                                />
                            </TableContainer> : <Grid container spacing={2}>
                                {filteredSensors.map((sensor) => (
                                    <Grid item xs={12} sm={4} md={4} key={sensor.sensoruuId}>
                                        <Card sx={{
                                            maxWidth: 300,
                                            boxShadow: 3,
                                            '&:hover': {
                                                border: '2px solid #D1E9FC',
                                                boxShadow: 'none'
                                            }
                                        }}>
                                            <CardHeader titleTypographyProps={{ variant: 'subtitle2' }} title={sensor.sensorName} />
                                            <Avatar
                                                sx={{
                                                    bgcolor: blue[900],
                                                    width: 120, // Adjust the width as needed
                                                    height: 120, // Adjust the height as needed
                                                    margin: 'auto',
                                                    marginLeft: '80px',// Center the avatar
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 2,
                                                }}
                                                src={sensor.sensorImg}
                                                aria-label="recipe"
                                            >
                                                {sensor.sensorImg === "" ? <EdgesensorHighTwoTone sx={{
                                                    fontSize: 60
                                                }} /> : sensor.sensorImg}
                                            </Avatar>
                                            <CardContent>
                                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                    Sensor Mac Id : {sensor.sensormacid}
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary', mt: 1 }}>
                                                    Sensor Type : {sensor.sensorType}
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary', mt: 1 }}>
                                                    Registered Date : {sensor.createdOn.split(' ')[0]}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleEditSensor(sensor)} startIcon={<Edit />}>Edit</Button>
                                                <Button variant="outlined" color="error" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleDeleteSensor(sensor.sensoruuId)} startIcon={<Delete />}>Delete</Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <center>No Sensor Found</center>
                        )}
                    </CardContent>
                </Card>
            </Container>
        </div >
    )
}

export default ManageSensor
