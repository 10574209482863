import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Preview from '../../layout/Preview';

//mui-components
import { Close, Done, ExpandMore, Home } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Container,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Stack, TextField,
    Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


//-----------------------------------------------------------------------------

const AddPatient = () => {

    const [image, setImage] = useState('');
    const [hmrNo, setHmrNo] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [bloodGroup, setBloodGroup] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [allergyDetails, setAllergyDetails] = useState('');
    const [pastComplicationsDetails, setPastComplicationsDetails] = useState('');

    const [selectedCompany, setSelectedCompany] = useState('');
    const [savePatientId, setSavedPatientId] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

    const [companies, setcompanies] = useState([]);
    const [physicians, setPhysicians] = useState([]);
    const [selectedPhysicians, setSelectedPhysicians] = useState([]);
    const tagOptions = [
        "Valve Disorder",
        "Angioplasty",
        // Add other existing tag options here
    ];
    const [selectedTags, setSelectedTags] = useState('');

    const [hmrNoError, setHmrNoError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [dobError, setDobError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [openPhysicianList, setOpenPhysicianList] = useState(false);
    const [hover, setHover] = useState(false);
    const [hideCompany, setHideCompany] = useState(false);
    const [hidePhysician, setHidePhysician] = useState(false);
    const [allergyDetailsError, setAllergyDetailsError] = useState(false);
    const [pastComplicationsDetailsError, setPastComplicationsDetailsError] = useState(false);


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [hasAllergies, setHasAllergies] = useState('No');
    const [hasPastComplications, setHasPastComplications] = useState('No');

    const handleAllergyChange = (event) => {
        setHasAllergies(event.target.value);
        if (event.target.value === 'No') {
            setAllergyDetails('');
        }
    };

    const handleCompliChange = (event) => {
        setHasPastComplications(event.target.value);
        if (event.target.value === 'No') {
            setPastComplicationsDetails('');
        }
    };


    useEffect(() => {
        var rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            var tenantId = secureLocalStorage.getItem("UUID");
            var companyId = 0;
            ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies")
                .then((response) => {
                    console.log("Company Details");
                    console.log("***********************************");
                    console.log(response.data);
                    const options = response.data.companies.map(d => ({
                        "value": d.companyuuid,
                        "label": d.name
                    }))
                    console.log(options);
                    setcompanies(options)
                })
        }

        const roleId = secureLocalStorage.getItem("roleId");
        const userId = secureLocalStorage.getItem("tenantMailId");
        ApiCalls.getRoleBasedMenu(userId, roleId)
            .then(function (response) {
                console.log(response.data);
                const menuArray = response.data;

                const hasManageCompany = menuArray.some(item => item.url === "/managecustomer");
                setHideCompany(!hasManageCompany);
                const hasManagePhysician = menuArray.some(item => item.url === "/managetherapist");
                setHidePhysician(!hasManagePhysician);

                console.log(hasManageCompany + ", " + hasManagePhysician);

                if (hasManageCompany) {
                    setHideCompany(false)
                    companyId = secureLocalStorage.getItem("compId");

                } else {
                    companyId = 0
                    setHideCompany(true);
                }

                if (hasManagePhysician) {
                    setHidePhysician(false);
                    getPhysicianList(companyId)
                } else {
                    setHidePhysician(true);
                }

            })

    }, [])

    const getPhysicianList = (companyId) => {
        var rolename = secureLocalStorage.getItem("rolename");
        var tenantId = "";
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
        }
        if (rolename === "Company") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("UUID");
        }
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/physicians")
            .then((response) => {
                console.log("Physician Details");
                console.log("***********************************");
                console.log(response.data);
                if (response.data != undefined) {
                    setPhysicians(response.data.physicians)
                } else {
                    setPhysicians([]);
                }
            })
    }

    const handleBlur = (event) => {
        if (!event.target.value && !hideCompany) {
            switch (event.target.name) {
                case 'company':
                    setCompanyError(true);
                    break;
                case 'hmrNo':
                    setHmrNoError(true);
                    break;
                case 'firstName':
                    setFirstNameError(true);
                    break;
                case 'lastName':
                    setLastNameError(true);
                    break;
                // case 'email':
                //     setEmailError(true);
                //     setEmailErrorMessage("Email address is required");
                //     break;
                case 'phone':
                    setPhoneError(true);
                    setPhoneErrorMessage("Phone Number is required");
                    break;
                case 'dob':
                    setDobError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'hmrNo':
                setHmrNoError(false);
                setHmrNo(event.target.value);
                break;
            case 'firstName':
                setFirstNameError(false);
                setFirstName(event.target.value);
                break;
            case 'lastName':
                setLastNameError(false);
                setLastName(event.target.value);
                break;
            case 'email':
                setEmailError(false);
                setEmail(event.target.value);
                break;
            case 'phone':
                setPhoneError(false);
                setPhone(event.target.value);
                break;
            // case 'dob':
            //     const selectedDate = new Date(event.target.value);
            //     const today = new Date();
            //     if (selectedDate >= today) {
            //         setDobError(true);
            //     } else {
            //         setDobError(false);
            //     }
            //     setDob(event.target.value);
            //     break;
            case 'dob':
                const inputDate = event.target.value;

                // Check if the input date is valid
                if (inputDate === '' || isNaN(new Date(inputDate))) {
                    setDobError(true);
                    setAge(''); // Reset age if DOB is invalid
                } else {
                    const selectedDate = new Date(inputDate);
                    const today = new Date();
                    if (selectedDate >= today) {
                        setDobError(true);
                    } else {
                        setDobError(false);
                    }

                    // Calculate age based on valid DOB
                    const diff = today - selectedDate;
                    const ageDate = new Date(diff);
                    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
                    setAge(calculatedAge);
                }
                setDob(inputDate);
                break;
            case 'tags':
                setSelectedTags(event.target.value);
                break;
            case 'height':
                setHeight(event.target.value);
                break;
            case 'weight':
                setWeight(event.target.value);
                break;
            case 'bloodGroup':
                setBloodGroup(event.target.value);
                break;
            case 'allergyDetails':
                setAllergyDetails(event.target.value);
                break;
            case 'pastComplicationsDetails':
                setPastComplicationsDetails(event.target.value);
                break;
            case 'street':
                setStreet(event.target.value);
                break;
            case 'city':
                setCity(event.target.value);
                break;
            case 'state':
                setState(event.target.value);
                break;
            case 'country':
                setCountry(event.target.value);
                break;
            case 'postalCode':
                setPostalCode(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const formatDate = (date) => {
        return format(new Date(date), 'MM/dd/yyyy');
    };

    const maxDate = formatDate(new Date());

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleClosePhysicianList = () => {
        setOpenPhysicianList(false);
        setRedirectToList(true);
    }

    const handleClick = () => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^[0-9]{10}$/;

        // Collect validation errors
        const errors = {};

        if (!hmrNo) {
            errors.hmrNoError = true;
        }
        if (!firstName) {
            errors.firstNameError = true;
        }
        if (!lastName) {
            errors.lastNameError = true;
        }
        if (!dob) {
            errors.dobError = true;
        }
        if (!phone) {
            errors.phoneError = true;
            errors.phoneErrorMessage = "Phone Number is required";
        } else if (!phoneRegex.test(phone)) {
            errors.phoneError = true;
            errors.phoneErrorMessage = "Invalid Phone Number";
        }
        if (hasAllergies === 'Yes' && !allergyDetails) {
            errors.allergyDetailsError = true;
        }
        if (hasPastComplications === 'Yes' && !pastComplicationsDetails) {
            errors.pastComplicationsDetailsError = true;
        }

        // Display errors if any
        if (Object.keys(errors).length > 0) {
            // Set all errors at once
            setHmrNoError(errors.hmrNoError || false);
            setFirstNameError(errors.firstNameError || false);
            setLastNameError(errors.lastNameError || false);
            setDobError(errors.dobError || false);
            setPhoneError(errors.phoneError || false);
            setPhoneErrorMessage(errors.phoneErrorMessage || "");
            setAllergyDetailsError(errors.allergyDetailsError || false);
            setPastComplicationsDetailsError(errors.pastComplicationsDetailsError || false);
            return; // Stop further execution
        } else {

            var companyId = secureLocalStorage.getItem("compId");
            var rolename = secureLocalStorage.getItem("rolename");
            var tenantId = secureLocalStorage.getItem("tenantId");
            var physicianId = "0";
            if (rolename === "Tenant") {
                tenantId = secureLocalStorage.getItem("UUID");
                companyId = selectedCompany;
                physicianId = "0";
            }
            if (rolename === "Company") {
                tenantId = secureLocalStorage.getItem("tenantId");
                companyId = secureLocalStorage.getItem("UUID");
                physicianId = "0";
            }
            if (rolename === "Physician") {
                tenantId = secureLocalStorage.getItem("tenantId");
                companyId = secureLocalStorage.getItem("companyId");
                physicianId = secureLocalStorage.getItem("UUID");
            }
            if (hideCompany && rolename === "Company") {
                companyId = secureLocalStorage.getItem("UUID");
            } else if (hideCompany && rolename === "Physician") {
                companyId = secureLocalStorage.getItem("companyId");
            } else if (!hideCompany) {
                companyId = "0";
            }
            else {
                companyId = selectedCompany;
            }

            const address = {
                street: street,
                postalcode: postalCode,
                city: city,
                state: state,
                country: country
            }

            var data = {
                tenantId: tenantId,
                companyId: companyId,
                physicianId: physicianId,
                patientId: hmrNo,
                firstName: firstName,
                lastName: lastName,
                email: email,
                dob: dob,
                age: age,
                gender: gender,
                roleId: "6",
                phone: phone,
                address: address,
                image: image == null ? "http://178.128.165.237/images/Patients/avatar_blank.png" : image,
                bloodGroup: bloodGroup,
                patientTag: selectedTags,
                height: height,
                weight: weight,
                allergies: hasAllergies === "No" ? hasAllergies : allergyDetails,
                pastComplications: hasPastComplications === "No" ? hasPastComplications : pastComplicationsDetails,
            }

            console.log(hideCompany + " " + hidePhysician);


            if (hidePhysician) {
                ApiCalls.register("organizations/0/tenants/" + tenantId + "/patients", data)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status.code === "200 OK") {
                            swal(secureLocalStorage.getItem("PatientName") + " Successfully Registered", {
                                icon: "success",
                            });
                            setRedirectToList(true);
                        } else if (response.data.status.details === "PatientId Already Registered") {
                            swal(secureLocalStorage.getItem("PatientName") + " Medical Record Number Already Registered", {
                                icon: "info",
                            });
                        } else if (response.data.status.details === "Patient Already Registered") {
                            swal(secureLocalStorage.getItem("PatientName") + " EmailId Already Registered", {
                                icon: "info",
                            });
                        }
                    })
            } else {
                ApiCalls.register("registerpatients", data)
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            swal(secureLocalStorage.getItem("PatientName") + " Successfully Registered", {
                                icon: "success",
                            });
                            setSavedPatientId(response.data.data.uuid);
                            if (hidePhysician) {

                            } else {
                                setOpenPhysicianList(true);
                            }

                        } else if (response.data.status.details === "PatientId Already Registered") {
                            swal(secureLocalStorage.getItem("PatientName") + " Medical Record Number Already Registered", {
                                icon: "info",
                            });
                        } else if (response.data.status.details === "Patient Already Registered") {
                            swal(secureLocalStorage.getItem("PatientName") + " Email Id Already Registered", {
                                icon: "info",
                            });
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
            }
        }
    }

    const handleCheckboxChange = (event, physician) => {
        if (event.target.checked) {
            setSelectedPhysicians([...selectedPhysicians, physician]);
        } else {
            setSelectedPhysicians(selectedPhysicians.filter((p) => p.physicianuuid !== physician.physicianuuid));
        }
    };

    const handleListItemClick = (event, physician) => {
        const checkbox = event.target.closest('input[type="checkbox"]');
        if (!checkbox) {
            const selectedIndex = selectedPhysicians.findIndex((p) => p.physicianuuid === physician.physicianuuid);
            if (selectedIndex === -1) {
                setSelectedPhysicians([...selectedPhysicians, physician]);
            } else {
                setSelectedPhysicians(selectedPhysicians.filter((p) => p.physicianuuid !== physician.physicianuuid));
            }
        }
    };

    const handleAssignPhysician = () => {
        console.log(selectedPhysicians);
        var roleId = secureLocalStorage.getItem("roleId");
        var tenantId = secureLocalStorage.getItem("tenantId");

        var a = [];
        var list = selectedPhysicians.length;

        for (var i = 0; i < list; i++) {
            var counters = selectedPhysicians[i];
            var physicianuuid = counters.physicianuuid;
            var s = { "physicianuuid": physicianuuid }
            a.push(s);
        }

        var rolename = secureLocalStorage.getItem("rolename");

        var data = {
            tenantId: tenantId,
            companyId: rolename === "Company" ? secureLocalStorage.getItem("UUID") : selectedCompany,
            physicians: a,
            patientuuid: savePatientId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            gender: gender,
            dob: dob,
            age: age,
            roleId: roleId,
            phone: phone,
            image: image === null ? "http://178.128.165.237/images/Patients/avatar_blank.png" : image
        };

        console.log("Final Data");
        console.log(data)
        ApiCalls.register("assignphysicians", data)
            .then((response) => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal(secureLocalStorage.getItem("PhysicianName") + " Successfully Assigned", {
                        icon: "success",
                    });
                    setRedirectToList(true);
                } else if (response.data.status.details === "Patient Already Registered") {
                    swal(secureLocalStorage.getItem("PatientName") + " Already Registered", {
                        icon: "info",
                    });
                }
            }).catch((err) => {
                console.log(err)
            })

    }

    if (redirectToList) {
        return (<Redirect to={'/managepatient'} />)
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managepatient">
                            <Button variant="text" startIcon={<Icon icon={'material-symbols:recent-patient-rounded'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                {"Manage " + secureLocalStorage.getItem("PatientName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Add " + secureLocalStorage.getItem("PatientName")} />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Add " + secureLocalStorage.getItem("PatientName")}
                        </Typography>}
                    />

                    <CardContent>
                        <Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 4">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 1, mx: 12 }}>
                                            <Avatar
                                                alt="Image"
                                                variant="rounded"
                                                src={image}
                                                sx={{
                                                    marginLeft: 5,
                                                    width: 150,
                                                    height: 150,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        transform: 'scale(1.1)',
                                                    },
                                                }}
                                                onMouseEnter={() => setHover(true)}
                                                onMouseLeave={() => setHover(false)}
                                                onClick={() => document.getElementById('avatar-input').click()}
                                            >
                                                <div className="icon-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                                                    {hover ? <Icon icon="line-md:upload-loop" color="#4481eb" width="80" /> : <Icon icon="ooui:user-avatar-outline" color="#4481eb" width="80" />}
                                                    <div className="text-container">
                                                        <Typography variant="subtitle2" display="block" color="primary" gutterBottom>{hover ? 'Upload' : ''}</Typography>
                                                    </div>
                                                </div>
                                            </Avatar>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                id="avatar-input"
                                                onChange={handleImageUpload}
                                                style={{ display: 'none', }}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 8">
                                    <Item>

                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="hmrNo"
                                                label={
                                                    <span>
                                                        Medical Record No{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={hmrNoError}
                                                helperText={hmrNoError && "HMR No is required"}
                                            />
                                            <TextField
                                                name="email"
                                                label="Email"
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={emailError}
                                                helperText={emailError && emailErrorMessage}
                                            />
                                        </Stack>
                                        <Stack sx={{ mt: 2 }} direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="firstName"
                                                label={
                                                    <span>
                                                        First Name{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={firstNameError}
                                                helperText={firstNameError && "First Name is required"}
                                            />
                                            <TextField
                                                name="lastName"
                                                label={
                                                    <span>
                                                        Last Name{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={lastNameError}
                                                helperText={lastNameError && "Last Name is required"}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                id="date"
                                                name="dob"
                                                label={
                                                    <span>
                                                        DOB{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                type="date"
                                                value={dob}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    max: maxDate,
                                                }}
                                                error={dobError}
                                                helperText={dobError && "Enter a valid DOB"}
                                            />
                                            <TextField
                                                fullWidth
                                                name="age"
                                                label="Age"
                                                value={age}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={gender}
                                                    label="Gender"
                                                    onChange={(e) => setGender(e.target.value)}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Select an option
                                                    </MenuItem>
                                                    <MenuItem value="Female">Female</MenuItem>
                                                    <MenuItem value="Male">Male</MenuItem>
                                                    <MenuItem value="Others">Others</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                fullWidth
                                                name="phone"
                                                type="number"
                                                label={
                                                    <span>
                                                        Phone{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={phoneError}
                                                helperText={phoneError && phoneErrorMessage}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="column" spacing={2}>
                                            <Accordion >
                                                <AccordionSummary expandIcon={<ExpandMore />} sx={{ minHeight: 'auto' }}>
                                                    <Typography variant='h5'>Medical Information</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Stack direction="column" spacing={2}>
                                                        <Stack direction="row" spacing={2}>
                                                            <div style={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    freeSolo
                                                                    options={tagOptions} // Array of existing tag options
                                                                    onChange={(event, newValue) => {
                                                                        // Handle the change in selected tags here
                                                                        setSelectedTags(newValue);
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            name="tags"
                                                                            label={secureLocalStorage.getItem("PatientName") + " Tags"}
                                                                            onChange={handleChange}
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <TextField
                                                                    name="height"
                                                                    type="number"
                                                                    label="Height (in cm)"
                                                                    onChange={handleChange}
                                                                    fullWidth
                                                                />
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <TextField
                                                                    name="weight"
                                                                    type="number"
                                                                    label="Weight (in kg)"
                                                                    onChange={handleChange}
                                                                    fullWidth
                                                                />
                                                            </div>

                                                            <div style={{ width: '100%' }}>
                                                                <TextField name="bloodGroup" label="Blood Group" onChange={handleChange} fullWidth />
                                                            </div>
                                                        </Stack>
                                                        <Stack direction="row" spacing={2}>
                                                            <div style={{ width: '100%' }}>
                                                                <Typography variant="body1">Any Allergies:</Typography>
                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <RadioGroup value={hasAllergies} onChange={handleAllergyChange} row>
                                                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                                                    </RadioGroup>
                                                                </div>
                                                            </div>
                                                            <TextField
                                                                name="allergyDetails"
                                                                label="Specify Allergy"
                                                                fullWidth
                                                                value={allergyDetails}
                                                                onChange={handleChange}
                                                                disabled={hasAllergies !== 'Yes'}
                                                                error={allergyDetailsError}
                                                                helperText={allergyDetailsError && "Specify the Allergy Detail"}
                                                            />
                                                            <div style={{ width: '100%' }}>
                                                                <Typography variant="body1">Any Past Complications:</Typography>
                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <RadioGroup value={hasPastComplications} onChange={handleCompliChange} row>
                                                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                                                    </RadioGroup>
                                                                </div>
                                                            </div>
                                                            <TextField
                                                                name="pastComplicationsDetails"
                                                                label="Specify Past Complications"
                                                                fullWidth
                                                                value={pastComplicationsDetails}
                                                                onChange={handleChange}
                                                                disabled={hasPastComplications !== 'Yes'}
                                                                error={pastComplicationsDetailsError}
                                                                helperText={allergyDetailsError && "Specify the Past Complication Detail"}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <Accordion>
                                                <AccordionSummary expandIcon={<ExpandMore />} sx={{ minHeight: 'auto' }}>
                                                    <Typography variant='h5'>Address Information</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Stack direction="row" spacing={2} justifyContent="space-between">
                                                        <TextField name="street" label="Street Address" onChange={handleChange} fullWidth />
                                                        <TextField name="city" label="City" fullWidth onChange={handleChange} />
                                                        <TextField name="state" label="State" fullWidth onChange={handleChange} />
                                                        <TextField name="postalCode" label="Postal Code" onChange={handleChange} fullWidth />
                                                        <TextField name="country" label="Country" fullWidth onChange={handleChange} />
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Stack>
                                    </Item>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Grid container spacing={1} justifyContent="flex-end">
                            <Grid item xs={6} md={6} lg={6}> {/* Adjust the grid sizes based on your layout */}
                                {/* Empty grid item for spacing */}
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}> {/* Adjust the grid sizes based on your layout */}
                                <Button
                                    fullWidth
                                    size="small"
                                    type="submit"
                                    color="error"
                                    variant="contained"
                                    onClick={() => setRedirectToList(true)}
                                    sx={{
                                        borderRadius: "12px",
                                        marginTop: "2px" // Add some spacing between the buttons
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}> {/* Adjust the grid sizes based on your layout */}
                                <Button
                                    fullWidth
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    onClick={handleClick}
                                    sx={{
                                        borderRadius: "12px",
                                        marginTop: "2px" // Add some spacing between the buttons
                                    }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Container>

            <div >
                <Dialog
                    sx={{
                        display: hidePhysician ? "none" : "block"
                    }}
                    open={openPhysicianList}
                    onClose={handleClosePhysicianList}
                    fullScreen={fullScreen}
                    aria-labelledby="responsive-dialog-title">
                    <Card>
                        <CardHeader
                            title={"Select " + secureLocalStorage.getItem("PhysicianName")}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleClosePhysicianList}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <List sx={{ pt: 0 }}>
                                {physicians.length > 0 ?
                                    physicians.map((physician) => (
                                        <ListItem disableGutters>

                                            <ListItemButton onClick={(event) => handleListItemClick(event, physician)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={selectedPhysicians.some((p) => p.physicianuuid === physician.physicianuuid)}
                                                        onChange={(event) => handleCheckboxChange(event, physician)}
                                                    />
                                                </ListItemIcon>
                                                <ListItemAvatar>
                                                    <Avatar sx={{ bgcolor: blue[100], color: blue[600], width: 50, height: 50 }} src={physician.image} />
                                                </ListItemAvatar>
                                                <ListItemText primary={physician.firstName + " " + physician.lastName} />
                                            </ListItemButton>

                                        </ListItem>
                                    )) : <Typography variant="body1">No {secureLocalStorage.getItem("PhysicianName")} available</Typography>

                                }
                            </List>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                fullWidth
                                startIcon={<Close />}
                                onClick={handleClosePhysicianList}
                            >Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                fullWidth
                                startIcon={<Done />}
                                onClick={handleAssignPhysician}
                            >Submit
                            </Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

        </div>
    )
}

export default AddPatient

