import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//internals
import DeviceImg from "../../assets/images/devices.png";
import Preview from "../../layout/Preview";

//mui-components
import { Cancel, Close, Edit, Home } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

//-----------------------------------------------------------------------------

const EditDevice = () => {

    const [name, setName] = useState('');
    const [macId, setMacId] = useState('');
    const [osVersion, setOsVersion] = useState('');
    const [modelName, setModelName] = useState('');
    const [devImg, setDevImg] = useState('');
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const [patientInfo, setPatientInfo] = useState('false');

    const [showIcon, setShowIcon] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [macIdError, setMacIdError] = useState(false);
    const [osVersionError, setOsVersionError] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [loadPatients, setLoadPatients] = useState(false);
    const [openAddMorePatient, setOpenAddMorePatient] = useState(false);
    const [loading, setLoading] = useState(false);

    const [assignedPatients, setAssignedPatients] = useState([]);
    const [patient, setPatient] = useState([]);
    const [selectedPatienttoAssign, setSelectedPatienttoAssign] = useState([]);

    // const maskDeviceId = (randomDeviceId) => {
    //     if (!randomDeviceId) return ""; // Handle case where randomDeviceId is undefined or null

    //     const prefix = randomDeviceId.substring(0, 4);
    //     const suffix = randomDeviceId.substring(randomDeviceId.length - 6);

    //     // Join prefix, maskedChars with "-", and suffix
    //     return `${prefix}xxxx-xxxx-xxxx-xxxx-${suffix}`;
    // }

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const loadDeviceDetailsById = (deviceId) => {
        ApiCalls.getdetails("device/" + deviceId + "/viewdevice")
            .then((response) => {
                console.log(response.data);
                if (response.data != undefined) {
                    setDevImg(response.data.deviceImage);
                    setName(response.data.devicename);
                    setMacId(response.data.randomDeviceId);
                    setOsVersion(response.data.osversion)
                    setModelName(response.data.modelName);
                }
            })
    }

    const loadDeviceAsignedPatients = (deviceId) => {
        setLoadPatients(true);
        ApiCalls.getdetails("device/" + deviceId + "/getPatientAdmittedStatus")
            .then((response) => {
                console.log(response.data);
                if (response.data.status.message === "InUse") {
                    setAssignedPatients(response.data.patient);
                    setLoadPatients(false);
                } else if (response.data.status.message === "NotInUse") {
                    setAssignedPatients([]);
                    setLoadPatients(false)
                }
            })
    }

    const loadPatientList = () => {
        setLoading(true);
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = "0";
            companyId = secureLocalStorage.getItem("UUID");
            physicianId = "0";
        } else if (rolename === "Physician") {
            tenantId = "0";
            companyId = "0";
            physicianId = secureLocalStorage.getItem("UUID");
        }

        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/physicians/" + physicianId + "/patients")
            .then((response) => {
                console.log(response.data);
                if (response.data.patient != undefined) {
                    setPatient(response.data.patient);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setPatient([]);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                setPatient([]);
            })
    }

    useEffect(() => {
        const macId = secureLocalStorage.getItem("deviceIdToEdit");
        const deviceId = secureLocalStorage.getItem("deviceIdSelected")
        console.log(macId)
        setSelectedDeviceId(deviceId);
        var roleId = secureLocalStorage.getItem("roleId");

        ApiCalls.getdetails("roleId/" + roleId + "/getpatientprivilegedetails")
            .then((response) => {
                console.log(response.data);
                if (response.data.patientprivilege != undefined) {
                    const patientInfo = response.data.patientprivilege[0].patientInfo;
                    console.log("PatientInfo: " + patientInfo);
                    setPatientInfo(patientInfo);
                    if (patientInfo === "true") {
                        loadDeviceAsignedPatients(macId);
                    }
                }
            })
        loadPatientList();
        loadDeviceDetailsById(macId);
    }, [])

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'name':
                    setNameError(true);
                    break;
                case 'macId':
                    setMacIdError(true);
                    break;
                case 'osversion':
                    setOsVersionError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'name':
                setNameError(false);
                setName(event.target.value);
                break;
            case 'macId':
                setMacIdError(false);
                setMacId(event.target.value);
                break;
            case 'osversion':
                setOsVersionError(false);
                setOsVersion(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleUpdate = () => {
        if (!name) {
            setNameError(true);
        } else if (!macId) {
            setMacIdError(true);
        } else if (!osVersion) {
            setOsVersionError(true);
        } else {
            const deviceId = secureLocalStorage.getItem("deviceIdToEdit");
            const data = {
                devicename: name,
                modelName: modelName,
                bleid: "",
                devmacid: macId,
            }
            console.log(data);
            ApiCalls.edit("devmacid/" + deviceId + "/updatedevicebymacid?", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal(secureLocalStorage.getItem("DeviceName") + " Successfully Updated", {
                            icon: "success",
                        });
                        setRedirectToList(true);
                    } else {
                        setRedirectToList(true);
                    }
                })
        }
    }


    const handleOpenPatientList = () => {
        setOpenAddMorePatient(true);
    }

    const handleClosePatientList = () => {
        setOpenAddMorePatient(false);
    }

    const handleDeselectPatient = (patient) => {
        console.log(patient);
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                var deviceId = selectedDeviceId;
                var patientId = patient.patientuuid;

                console.log(deviceId, patientId)

                ApiCalls.delete("device/" + deviceId + "/patient/" + patientId + "/deselectDevice")
                    .then(function (response) {
                        console.log(response.data);
                        if (response.data.status.message === "Success") {
                            swal("Deselected Successfully!", {
                                icon: "success",
                            });
                            loadDeviceAsignedPatients(macId);
                        }
                    })
            }
        })
    }

    if (redirectToList) {
        return (
            <Redirect to={'/managedevice'} />
        )
    }

    const handleAssignPatienttoDevice = () => {
        console.log(selectedPatienttoAssign[0].id);
        console.log(selectedDeviceId);

        var data = {
            patients: [
                {
                    patientuuid: selectedPatienttoAssign[0].id
                }
            ]
        }

        ApiCalls.register("device/" + selectedDeviceId + "/patient/assign", data)
            .then((response => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal((secureLocalStorage.getItem("DeviceName") + " Assigned"), {
                        icon: "success",
                    });
                    handleClosePatientList();
                    loadDeviceAsignedPatients(macId);
                } else if (response.data.status.details === "Device Already Assigned To The Patient") {
                    swal((secureLocalStorage.getItem("DeviceName") + " Already Assigned"), {
                        icon: "warning",
                    });
                }
            })).catch((error) => {
                console.log(error.message);
            });
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managedevice">
                            <Button variant="text" startIcon={<Icon icon={'tabler:devices-plus'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                {"Manage " + secureLocalStorage.getItem("DeviceName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Edit " + secureLocalStorage.getItem("DeviceName")} />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Edit " + secureLocalStorage.getItem("DeviceName")}
                        </Typography>}
                    />
                    <CardContent>
                        <Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 4">
                                    <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ my: 2 }}>
                                        <Box gridColumn="span 4" sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}>
                                            <Item>
                                                <Avatar
                                                    alt="image"
                                                    src={devImg == undefined || devImg === "null" ? DeviceImg : devImg}
                                                    variant="rounded"
                                                    sx={{
                                                        marginLeft: 5,
                                                        width: 200,
                                                        height: 200,
                                                        transition: 'all 0.2s ease-in-out',
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            transform: 'scale(1.1)',
                                                        },
                                                    }}
                                                    onClick={() => document.getElementById('avatar-input').click()}
                                                    onMouseEnter={() => setShowIcon(true)}
                                                    onMouseLeave={() => setShowIcon(false)}
                                                />
                                            </Item>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box gridColumn="span 6">
                                    <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                        <TextField
                                            name="name"
                                            id="name"
                                            label={
                                                <span>
                                                    {secureLocalStorage.getItem("DeviceName") + " Name"}{' '}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            value={name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            error={nameError}
                                            helperText={nameError && secureLocalStorage.getItem("DeviceName") + " Name is required"}
                                        />
                                        <TextField
                                            name="macId"
                                            id="macId"
                                            label={
                                                <span>
                                                    UUID{' '}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            value={macId}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            error={macIdError}
                                            helperText={macIdError && "Android Id is required"}
                                            InputProps={{
                                                readOnly: true,
                                            }} // Add this prop to make it read-only
                                        />
                                        <TextField
                                            name="osversion"
                                            id="osversion"
                                            label={
                                                <span>
                                                    OS Version{' '}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </span>
                                            }
                                            value={osVersion}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                            error={osVersionError}
                                            helperText={osVersionError && "OS Version is required"}
                                        />
                                        <TextField
                                            name="modelName"
                                            id="modelName"
                                            label="Model Name"
                                            InputProps={{
                                                readOnly: true,
                                            }} // Add this prop to make it read-only
                                            value={modelName}
                                            fullWidth
                                        />
                                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ my: 2 }}>
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="error"
                                                onMouseDown={handleMouseDown}
                                                onClick={() => setRedirectToList(true)}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                startIcon={<Cancel />}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                onMouseDown={handleMouseDown}
                                                onClick={handleUpdate}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                                startIcon={<Edit />}
                                            >
                                                Update
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>

                <Card sx={{
                    minWidth: 300,
                    marginTop: 2,
                    display: patientInfo === "false" ? 'none' : ''
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Assigned " + secureLocalStorage.getItem("PatientName")}
                        </Typography>}
                    />

                    <CardContent sx={{ maxHeight: 300 }}>
                        {loadPatients ? (
                            <center>
                                <CircularProgress />
                            </center>
                        ) : (
                            <Grid container spacing={2}>
                                {
                                    assignedPatients.length > 0 ?
                                        assignedPatients.map((patient) => (
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Card sx={{
                                                    position: 'relative',
                                                    boxShadow: 3,
                                                    '&:hover .delete-icon': {
                                                        display: 'block',
                                                    },
                                                }}>
                                                    <CardHeader
                                                        avatar={
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Avatar
                                                                    alt={patient.firstName + " " + patient.lastName}
                                                                    src={patient.image}
                                                                    sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                    <Box sx={{ mb: 2, ml: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                        <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                            {patient.firstName + " " + patient.lastName}
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                            <Icon icon={'solar:user-id-bold'} /> {patient.patientId}
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                            <Icon icon={'material-symbols:ward-rounded'} /> {patient.wardNo + "-" + patient.bedNo}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        } />
                                                </Card>
                                            </Grid>
                                        )) :
                                        <CardHeader
                                            avatar={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {"No " + secureLocalStorage.getItem("PatientName") + " Assigned"}
                                                </Box>
                                            } />
                                }
                            </Grid>
                        )}
                    </CardContent>
                </Card>

                <div style={{
                    height: "15px"
                }} />

                <div>
                    <Dialog open={openAddMorePatient} onClose={handleClosePatientList}>
                        <CardHeader
                            title={"Add " + secureLocalStorage.getItem("PatientName")}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleClosePatientList}>
                                    <Close />
                                </IconButton>
                            }
                        />

                        <CardContent>
                            {loading ? (
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 200 }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    {
                                        patient.length > 0 ?
                                            <>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    maxHeight: 400,
                                                }} >
                                                    {patient
                                                        .map((patient) => (
                                                            <FormControlLabel
                                                                key={patient.patientuuid}
                                                                control={<Checkbox
                                                                    checked={selectedPatienttoAssign.some(
                                                                        (d) => d.id === patient.patientuuid
                                                                    )}
                                                                    onChange={(e) => {
                                                                        const checked = e.target.checked;
                                                                        const patientId = patient.patientuuid;
                                                                        const patientName = patient.firstName + " " + patient.lastName;
                                                                        if (checked) {
                                                                            setSelectedPatienttoAssign([{ id: patientId, name: patientName }]);
                                                                        } else {
                                                                            setSelectedPatienttoAssign([]);
                                                                        }
                                                                    }}
                                                                    value={patient.patientuuid}
                                                                />}
                                                                label={<>
                                                                    <CardHeader
                                                                        avatar={
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Avatar
                                                                                    variant='rounded'
                                                                                    alt={patient.firstName + " " + patient.lastName}
                                                                                    src={patient.image}
                                                                                    sx={{ width: 80, height: 80, objectFit: "contain" }} />
                                                                                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', ml: 2 }}>
                                                                                    <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                                        <Typography variant="h6" sx={{ color: 'text.primary' }}>
                                                                                            {patient.firstName + " " + patient.lastName}
                                                                                        </Typography>
                                                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary', ml: 2 }}>
                                                                                            {patient.gender}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        } />

                                                                </>}
                                                            />
                                                        ))}
                                                </Box>
                                            </>
                                            : <Box sx={{

                                                alignItems: "center"  // Center the content vertically
                                            }}>
                                                <div style={{ margin: "auto", textAlign: "center" }}>
                                                    No {secureLocalStorage.getItem("DeviceName")} Found
                                                </div>
                                            </Box>
                                    }
                                </>
                            )}
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" size="small" onMouseDown={handleMouseDown} onClick={handleClosePatientList} fullWidth >Cancel</Button>
                            <Button variant="contained" color="primary" size="small" onMouseDown={handleMouseDown} disabled={!selectedPatienttoAssign.length > 0} onClick={handleAssignPatienttoDevice} fullWidth >Submit</Button>
                        </CardActions>
                    </Dialog>
                </div>

            </Container>
        </div>
    )
}

export default EditDevice
