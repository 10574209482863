import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Preview from '../../layout/Preview';

//mui-components
import { Close, Done, Home } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Container,
    Dialog,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import { format } from 'date-fns';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


//-----------------------------------------------------------------------------

const AddCareGiver = () => {

    const [image, setImage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState(new Date().toISOString().slice(0, 10));
    const [gender, setGender] = useState('Female');
    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [savePatientId, setSavedPatientId] = useState('');

    const [companies, setcompanies] = useState([]);
    const [physicians, setPhysicians] = useState([]);
    const [selectedPhysicians, setSelectedPhysicians] = useState([]);

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [dobError, setDobError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [openPhysicianList, setOpenPhysicianList] = useState(false);
    const [hover, setHover] = useState(false);
    const [hideCompany, setHideCompany] = useState(false);
    const [hidePhysician, setHidePhysician] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = 0;
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies")
            .then((response) => {
                console.log("Company Details");
                console.log("***********************************");
                console.log(response.data);
                const options = response.data.companies.map(d => ({
                    "value": d.companyuuid,
                    "label": d.name
                }))
                console.log(options);
                setcompanies(options)
            })

        const roleId = secureLocalStorage.getItem("roleId");
        const userId = secureLocalStorage.getItem("tenantMailId");
        ApiCalls.getRoleBasedMenu(userId, roleId)
            .then(function (response) {
                console.log(response.data);
                const menuArray = response.data;

                const hasManageCompany = menuArray.some(item => item.url === "/managecustomer");
                setHideCompany(!hasManageCompany);
                const hasManagePhysician = menuArray.some(item => item.url === "/managetherapist");
                setHidePhysician(!hasManagePhysician);

                console.log(hasManageCompany + ", " + hasManagePhysician);

                if (hasManageCompany) {
                    setHideCompany(false)
                    companyId = secureLocalStorage.getItem("compId");

                } else {
                    companyId = 0
                    setHideCompany(true);
                }

                if (hasManagePhysician) {
                    // if (secureLocalStorage.getItem("physName") === null) {
                    //     setHidePhysician(true);
                    // } else {
                    //     getPhysicianList(companyId)
                    // }
                    setHidePhysician(false);
                    getPhysicianList(companyId)
                } else {
                    setHidePhysician(true);
                }

            })
    }, [])

    const getPhysicianList = (companyId) => {
        var rolename = secureLocalStorage.getItem("rolename");
        var tenantId = "";
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
        }
        if (rolename === "Company") {
            tenantId = secureLocalStorage.getItem("tenantId");
        }
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/physicians")
            .then((response) => {
                console.log("Physician Details");
                console.log("***********************************");
                console.log(response.data);
                if (response.data != undefined) {
                    setPhysicians(response.data.physicians)
                } else {
                    setPhysicians([]);
                }
            })
    }

    const handleBlur = (event) => {
        if (!event.target.value && !hideCompany) {
            switch (event.target.name) {
                case 'company':
                    setCompanyError(true);
                    break;
                case 'firstName':
                    setFirstNameError(true);
                    break;
                case 'lastName':
                    setLastNameError(true);
                    break;
                case 'email':
                    setEmailError(true);
                    break;
                case 'phone':
                    setPhoneError(true);
                    break;
                case 'dob':
                    setDobError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'firstName':
                setFirstNameError(false);
                setFirstName(event.target.value);
                break;
            case 'lastName':
                setLastNameError(false);
                setLastName(event.target.value);
                break;
            case 'email':
                setEmailError(false);
                setEmail(event.target.value);
                break;
            case 'phone':
                setPhoneError(false);
                setPhone(event.target.value);
                break;
            case 'dob':
                const selectedDate = new Date(event.target.value);
                const today = new Date();
                if (selectedDate >= today) {
                    setDobError(true);
                } else {
                    setDobError(false);
                }
                setDob(event.target.value);
                break;
            case 'street':
                setStreet(event.target.value);
                break;
            case 'city':
                setCity(event.target.value);
                break;
            case 'state':
                setState(event.target.value);
                break;
            case 'country':
                setCountry(event.target.value);
                break;
            case 'postalCode':
                setPostalCode(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const formatDate = (date) => {
        return format(new Date(date), 'MM/dd/yyyy');
    };

    const maxDate = formatDate(new Date());

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleClosePhysicianList = () => {
        setOpenPhysicianList(false);
    }

    const handleClick = () => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^[0-9]{10}$/;

        if (!firstName) {
            setFirstNameError(true);
        } else if (!lastName) {
            setLastNameError(true);
        } else if (!email) {
            setEmailError(true);
        } else if (!emailRegex.test(email)) {
            setEmailError(true);
        } else if (!dob) {
            setDobError(true);
        } else if (!phone) {
            setPhoneError(true);
        } else if (!phoneRegex.test(phone)) {
            setPhoneError(true);
        } else {
            var tenantId = secureLocalStorage.getItem("tenantId");
            var companyId = selectedCompany;
            if (hideCompany) {
                companyId = "0"
            }
            else {
                companyId = selectedCompany;
            }

            const address = {
                street: street,
                postalcode: postalCode,
                city: city,
                state: state,
                country: country
            }

            var data = {
                tenantId: tenantId,
                companyId: companyId,
                physicianId: "0",// physicianId,
                firstName: firstName,
                lastName: lastName,
                email: email,
                dob: dob,
                gender: gender,
                roleId: "6",
                phone: phone,
                address: address,
                image: image === null ? "http://178.128.165.237/images/Patients/avatar_blank.png" : image
            }

            console.log(hideCompany + " " + hidePhysician);

            // if (hidePhysician) {
            //     ApiCalls.register("organizations/0/tenants/" + tenantId + "/caregivers", data)
            //         .then((response) => {
            //             console.log(response.data);
            //             if (response.data.status.code === "200 OK") {
            //                 swal(secureLocalStorage.getItem("CareGiverName") + " Successfully Registered", {
            //                     icon: "success",
            //                 });
            //                 setRedirectToList(true);
            //             }
            //         })
            // } else {
            ApiCalls.register("registercaregivers", data)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        setSavedPatientId(response.data.data.uuid);
                        setOpenPhysicianList(true);
                    } else if (response.data.status.details === "CareGiver Already Registered") {
                        swal(secureLocalStorage.getItem("CaregiverName") + " Already Registered", {
                            icon: "info",
                        });
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }
    }


    const handleCheckboxChange = (event, physician) => {
        if (event.target.checked) {
            setSelectedPhysicians([...selectedPhysicians, physician]);
        } else {
            setSelectedPhysicians(selectedPhysicians.filter((p) => p.physicianuuid !== physician.physicianuuid));
        }
    };

    const handleListItemClick = (event, physician) => {
        const checkbox = event.target.closest('input[type="checkbox"]');
        if (!checkbox) {
            const selectedIndex = selectedPhysicians.findIndex((p) => p.physicianuuid === physician.physicianuuid);
            if (selectedIndex === -1) {
                setSelectedPhysicians([...selectedPhysicians, physician]);
            } else {
                setSelectedPhysicians(selectedPhysicians.filter((p) => p.physicianuuid !== physician.physicianuuid));
            }
        }
    };

    const handleAssignPhysician = () => {
        console.log(selectedPhysicians);
        var roleId = secureLocalStorage.getItem("roleId");
        console.log(roleId);
        console.log(secureLocalStorage.getItem("UUID"));
        if(roleId === "4") {
            setSelectedCompany(secureLocalStorage.getItem("UUID"))
        }
        var tenantId = secureLocalStorage.getItem("tenantId");

        var a = [];
        var list = selectedPhysicians.length;

        for (var i = 0; i < list; i++) {
            var counters = selectedPhysicians[i];
            var physicianuuid = counters.physicianuuid;
            var s = { "physicianuuid": physicianuuid }
            a.push(s);
        }

        var data = {
            tenantId: tenantId,
            companyId: selectedCompany,
            physicians: a,
            patientuuid: savePatientId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            gender: gender,
            dob: dob,
            roleId: roleId,
            phone: phone,
            image: image === null ? "http://178.128.165.237/images/Patients/avatar_blank.png" : image
        };

        console.log("Final Data");
        console.log(data)
        ApiCalls.register("assignphysiciansToCaregiver", data)
            .then((response) => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal(secureLocalStorage.getItem("CaregiverName") + " Successfully Registered", {
                        icon: "success",
                    });
                    setRedirectToList(true);
                } else if (response.data.status.details === "Caregiver Already Registered") {
                    swal(secureLocalStorage.getItem("CaregiverName") + " Already Registered", {
                        icon: "info",
                    });
                }
            }).catch((err) => {
                console.log(err)
            })

    }

    if (redirectToList) {
        return (<Redirect to={'/managecaregiver'} />)
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managecaregiver">
                            <Button variant="text" startIcon={<Icon icon={'material-symbols:recent-patient-rounded'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                {"Manage " + secureLocalStorage.getItem("CareGiverName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Add " + secureLocalStorage.getItem("CareGiverName")} />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Add " + secureLocalStorage.getItem("CareGiverName")}
                        </Typography>}
                    />

                    <CardContent>
                        <Box sx={{ width: 1 }}>
                            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                <Box gridColumn="span 4">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                            <Avatar
                                                alt="Image"
                                                variant="circular"
                                                src={image}
                                                sx={{
                                                    marginLeft: 5,
                                                    width: 150,
                                                    height: 150,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        transform: 'scale(1.1)',
                                                    },
                                                }}
                                                onMouseEnter={() => setHover(true)}
                                                onMouseLeave={() => setHover(false)}
                                                onClick={() => document.getElementById('avatar-input').click()}
                                            >
                                                <div className="icon-container" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                                                    {hover ? <Icon icon="line-md:upload-loop" color="#4481eb" width="80" /> : <Icon icon="ooui:user-avatar-outline" color="#4481eb" width="80" />}
                                                    <div className="text-container">
                                                        <Typography variant="subtitle2" display="block" color="primary" gutterBottom>{hover ? 'Upload' : ''}</Typography>
                                                    </div>
                                                </div>
                                            </Avatar>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                id="avatar-input"
                                                onChange={handleImageUpload}
                                                style={{ display: 'none', }}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 8">
                                    <Item>
                                        <Stack direction="row" spacing={2} sx={{ my: 2, display: hideCompany ? "none" : "block" }} >
                                            <FormControl fullWidth >
                                                <InputLabel id="demo-simple-select-label">{"Select " + secureLocalStorage.getItem("CompanyName")}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name="company"
                                                    label={"Select " + secureLocalStorage.getItem("CompanyName")}
                                                    value={selectedCompany}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        setSelectedCompany(e.target.value)
                                                        setCompanyError(false)
                                                    }}
                                                    error={companyError}
                                                >
                                                    {companies.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText error={companyError} style={{ color: companyError ? 'red' : '' }}>
                                                    {companyError && "Select a " + secureLocalStorage.getItem("CompanyName")}
                                                </FormHelperText>
                                            </FormControl>
                                        </Stack>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="firstName"
                                                label="First Name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={firstNameError}
                                                helperText={firstNameError && "First Name is required"}
                                            />
                                            <TextField
                                                name="lastName"
                                                label="Last Name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                error={lastNameError}
                                                helperText={lastNameError && "Last Name is required"}
                                            />
                                            <TextField
                                                name="email"
                                                label="Email"
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={emailError}
                                                helperText={emailError && "Email address is required"}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                id="date"
                                                name="dob"
                                                label="DOB"
                                                type="date"
                                                value={dob}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    max: maxDate,
                                                }}
                                                error={dobError}
                                                helperText={dobError && "Enter a valid DOB"}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={gender}
                                                    label="Gender"
                                                    onChange={(e) => setGender(e.target.value)}
                                                >
                                                    <MenuItem value="Female">Female</MenuItem>
                                                    <MenuItem value="Male">Male</MenuItem>
                                                    <MenuItem value="Others">Others</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                fullWidth
                                                name="phone"
                                                type="number"
                                                label="Phone"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={phoneError}
                                                helperText={phoneError && "Phone Number is required"}
                                            />
                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="street"
                                                label="Street Address"
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                            <TextField
                                                name="city"
                                                label="City"
                                                fullWidth
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                name="state"
                                                label="State"
                                                fullWidth
                                                onChange={handleChange}
                                            />

                                        </Stack>
                                    </Item>
                                </Box>
                                <Box gridColumn="span 12">
                                    <Item>
                                        <Stack direction="row" spacing={2} justifyContent="space-between">
                                            <TextField
                                                name="postalCode"
                                                label="Postal Code"
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                            <TextField
                                                name="country"
                                                label="Country"
                                                fullWidth
                                                onChange={handleChange}
                                            />
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                color="error"
                                                variant="contained"
                                                onClick={() => setRedirectToList(true)}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                onClick={handleClick}
                                                sx={{
                                                    borderRadius: "12px",
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </Stack>
                                    </Item>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Container>

            <div >
                <Dialog
                    open={openPhysicianList}
                    onClose={handleClosePhysicianList}
                    fullScreen={fullScreen}
                    aria-labelledby="responsive-dialog-title">
                    <Card>
                        <CardHeader
                            title={"Select " + secureLocalStorage.getItem("PhysicianName")}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleClosePhysicianList}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <List sx={{ pt: 0 }}>
                                {physicians.map((physician) => (
                                    <ListItem disableGutters>

                                        <ListItemButton onClick={(event) => handleListItemClick(event, physician)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={selectedPhysicians.some((p) => p.physicianuuid === physician.physicianuuid)}
                                                    onChange={(event) => handleCheckboxChange(event, physician)}
                                                />
                                            </ListItemIcon>
                                            <ListItemAvatar>
                                                <Avatar sx={{ bgcolor: blue[100], color: blue[600], width: 50, height: 50 }} src={physician.image} />
                                            </ListItemAvatar>
                                            <ListItemText primary={physician.firstName + " " + physician.lastName} />
                                        </ListItemButton>

                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                fullWidth
                                startIcon={<Close />}
                                onClick={handleClosePhysicianList}
                            >Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                fullWidth
                                startIcon={<Done />}
                                onClick={handleAssignPhysician}
                            >Submit
                            </Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>
        </div>
    )
}

export default AddCareGiver

