import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

//mui-components
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

//internals
import secureLocalStorage from 'react-secure-storage';
import NotFoundImg from "../../assets/illustrations/404.jpg";

//styled components
const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------


const NotFound = () => {

    useEffect(() => {
        console.log(secureLocalStorage.getItem("isSignedIn"));
        console.log(secureLocalStorage.getItem("roleId"));
    }, [])

    return (
        <>
            <Container>
                <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <Typography variant="h3" paragraph>
                        Sorry, page not found!
                    </Typography>

                    <Typography sx={{ color: 'text.secondary' }}>
                        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
                        spelling.
                    </Typography>

                    <Box
                        component="img"
                        src={NotFoundImg}
                        sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
                    />

                    <Button
                        to={
                            secureLocalStorage.getItem("isSignedIn") == true
                                ? (secureLocalStorage.getItem("roleId") === "8"
                                    ? "/mydashboard"
                                    : "/dashboard")
                                : "/"
                        }
                        size="small"
                        variant="contained"
                        component={RouterLink}>
                        Go to Home
                    </Button>
                </StyledContent>
            </Container>
        </>
    )
}

export default NotFound
