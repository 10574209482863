import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, List, ListItemText } from "@mui/material";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List
        disablePadding
        sx={{ p: 1, overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
      >
        {data.map((item, index) => (
          <NavItem
            key={item.id}
            item={item}
            isLastItem={index === data.length - 1}
          />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  isLastItem: PropTypes.bool,
};

function NavItem({ item, isLastItem }) {
  const { submenu, url, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={url}
      sx={{
        "&.active": {
          color: "text.primary",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
        },
        ...(isLastItem && { marginBottom: 10 }), // Apply margin if it's the last item
      }}
    >
      <StyledNavItemIcon>
        <img src={icon} alt={submenu} />
      </StyledNavItemIcon>

      <ListItemText disableTypography primary={submenu} />
    </StyledNavItem>
  );
}
