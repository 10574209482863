import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputBase,
  Paper,
} from "@mui/material";

const EditFields = ({ setActiveStep, setRolenames, rolenames,setSelectedRoles,userId,}) => {
 
  const [IsInEditMode, setIsInEditMode] = useState(false);
  const [focus, setFocus] = useState(true);
  const [base, setBase] = useState(true);

  useEffect(() => {
    console.log(rolenames);
  }, []);

  const editAll = () => {
    setFocus(false);
    setBase(false);
    alert("Tap the Text to Edit");
  };

  const Rolecheck = (e, s) => {
    e.preventDefault();
    setRolenames((prevRolenames) =>
      prevRolenames.map((rolename) =>
        rolename.roleId === e.target.name
          ? { ...rolename, newFieldName: s }
          : rolename
      )
    );
  };

  const handleCheckboxChange = (e, index, s) => {

    setRolenames((prevRolenames) =>
      prevRolenames.map((rolename, i) =>
        i === index ? { ...rolename, checked: e.target.checked } : rolename
      ) 

    );
    
    console.log(rolenames)
  };

  // Define a state variable to store the role data for logging
  const [roleForLogging, setRoleForLogging] = useState(rolenames);

const forwardSubmit = () => {
  // Filter the selected roles
  const selectedRoles = rolenames.filter((rolename) => rolename.checked);
 
  const rolenamesWithUserId = rolenames.map((role) => ({ ...role, userId }));

  // Log the selected roles to the console
  console.log("Selected Roles:", selectedRoles);
  console.log("Selected Roles:", rolenamesWithUserId);
  
  // Continue with your logic

  setSelectedRoles(rolenamesWithUserId);
  setSelectedRoles(selectedRoles);
  setBase(true);
};

  // Use useEffect to update the roleForLogging state whenever role changes


  const roleSelect = rolenames.map((rolename, index) => (
    <Paper
      sx={{
        backgroundColor: "#F4F6F8",
        boxShadow: 5,
        my: 1,
        height: 100,
        maxWidth: 500,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        px: 3,
        py: 3,
      }}
      key={index}
    >
      <FormControlLabel
        control={
          <Checkbox
          value={rolename.roleName}
          name={rolename.roleId}
          checked={rolename.checked}
          onChange={(e) => handleCheckboxChange(e, index, rolename.roleName)}
        />
        
        }
        label={
          <InputBase
            readOnly={focus}
            name={rolename.roleId}
            sx={
              base
                ? {
                    color: "#090A0A",
                  }
                : {
                    color: "#1976D2",
                    bordercolor: "blue",
                    border: "2px solid #38A3A5",
                    borderRadius: "5px",
                    width: "200px",
                    height: "50px",
                    px: 2,
                  }
            }
            autoFocus={base}
            defaultValue={rolename.oldFieldName}
            onChange={(e) => {
              rolename.newFieldName = e.target.value;
              Rolecheck(e, rolename.oldFieldName);
            }}
          />
        }
      />
    </Paper>
  ));

  return (
    <div bgcolor="#E8F6EF">
      <div style={{ color: "F5F5F5" }}>
        {base ? (
          <Button
            style={{
              fontFamily: "Lato, sans-serif",
              backgroundColor: "#51C4D3",
              Alignitem: "center",
              marginBottom: "20px",
            }}
            onClick={editAll}
            variant="contained"
          >
            Change Field Names
          </Button>
        ) : (
          <Button
            style={{
              fontFamily: "Lato, sans-serif",
              backgroundColor: "#51C4D3",
              Alignitem: "center",
              marginBottom: "10px",
            }}
            onClick={forwardSubmit}
            variant="contained"
          >
            Done Editing
          </Button>
        )}
        <div style={{ color: "F5F5F5" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {roleSelect}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EditFields;
