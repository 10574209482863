import React, { useEffect, useState } from 'react';

//mui-components
import { Close, Delete, Done, Edit } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Collapse,
    Container,
    Dialog,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//components
import Iconify from '../../components/iconify/Iconify';

//externals
import { Icon } from '@iconify/react';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

//internals
import { MenuProps } from "./utils";

//-----------------------------------------------------------------------------------------------------------

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
    })
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

//-----------------------------------------------------------------------------------------------------------


const ParameterGroupList = ({ data, handleChange, handleNext, handleBack, selectedGroups, setSelectedGroups }) => {
    const [paramterGroup, setParameterGroup] = useState([]);
    const [expandedCards, setExpandedCards] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedGrouptoEdit, setSelctedGrouptoEdit] = useState([]);


    const [groupName, setGroupName] = useState('');
    const [groupType, setGroupType] = useState(1);
    const [groupTag, setGroupTag] = useState('');

    const [groupNameError, setGroupNameError] = useState(false);
    const [parametersError, setParametersError] = useState(false);
    const [loadParameterGroup, setLoadParameterGroup] = useState(false);
    const [openAddNewGroup, setOpenAddNewGroup] = useState(false);
    const [openEditGroup, setOpenEditGroup] = useState(false);
    const [skip, setSkip] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const storedValues = JSON.parse(secureLocalStorage.getItem("selectedParameters")) || [];
        setSelectedOptions(storedValues);

        console.log(storedValues);
        console.log(selectedGroups);
    }, []);

    const filteredParameterGroups = paramterGroup.filter((group) =>
        group.groupName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredSelectedGroups = selectedGroups.filter((group) =>
        group.groupName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleExpandClick = (index) => {
        setExpandedCards((prevExpandedCards) => {
            if (prevExpandedCards.includes(index)) {
                return prevExpandedCards.filter((i) => i !== index);
            } else {
                return [index];
            }
        });
    };

    const loadParameterGroupList = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = "0";
        } else if (rolename === "Company") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("UUID");
        }
        ApiCalls.getdetails("tenant/" + tenantId + "/company/" + companyId + "/physician/0/parametergroup?")
            .then((response) => {
                console.log(response.data);
                if (response.data.parameterGroup != undefined) {
                    setLoadParameterGroup(false);
                    setParameterGroup(response.data.parameterGroup);
                    setSkip(false);
                } else {
                    setLoadParameterGroup(false);
                    setParameterGroup([]);
                    setSkip(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoadParameterGroup(false);
                setParameterGroup([]);
                setSkip(true);
            })
    }

    useEffect(() => {
        loadParameterGroupList();
    }, [])

    const onButtonSkipped = (e) => {
        if (!skip || selectedGroups) {
            handleNext();
        } else {
            swal({
                title: "Are you sure?",
                text: "To Create a Group '" + data.modelName + "' with selected parameters",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    handleNext();
                    e.preventDefault();
                    var a = [];
                    var list = selectedOptions.length;
                    console.log("list:" + list);
                    for (var i = 0; i < list; i++) {
                        var counter = selectedOptions[i];
                        const paramId = counter.paramId;
                        var s = { "paramId": paramId }
                        a.push(s);
                        console.log("s:" + JSON.stringify(s))
                    }
                    console.log("a:" + JSON.stringify(a))
                    onSkip(data, a);
                }

            })
        }

    }

    const onSkip = (data, a) => {
        var s = {
            groupName: data.modelName,
            type: "1",
            tags: null,
            parameters: a
        };
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("companyId");
        ApiCalls.register("Tenant/" + tenantId + "/company/" + companyId + "/parametergroup", s)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    handleNext();
                }
            })
    };

    const handleOpenEditGroup = (group) => {
        setOpenEditGroup(true);
        console.log(group);
        setSelctedGrouptoEdit(group);
        setGroupName(group.groupName);
        setGroupType(group.type)
        setGroupTag(group.tags)
        setSelected(group.parameters);
    }

    const handleCloseEditGroup = () => {
        setOpenEditGroup(false);
        setSelctedGrouptoEdit([]);
    }

    const handleTypeChange = (event) => {
        const selectedType = event.target.value;
        console.log(selectedType);
        setGroupType(selectedType)
        setSelctedGrouptoEdit(prevState => ({
            ...prevState,
            groupType: selectedType
        }));
    };


    const handleCloseAddGroup = () => {
        setOpenAddNewGroup(false);
        setSelected(selectedOptions.filter((option) => selected.includes(option)));

        loadParameterGroupList();
    }

    const handleSelectParameters = (event) => {
        const value = event.target.value;
        setSelected(value);
        setParametersError(false);

        console.log(selected)
    };

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'groupName':
                    setGroupNameError(true);
                    break;
                case 'parameters':
                    setParametersError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleOnChange = (event) => {
        switch (event.target.name) {
            case 'groupName':
                setGroupNameError(false);
                setGroupName(event.target.value);
                break;
            case 'type':
                setGroupType(event.target.value);
                break;
            case 'groupTag':
                setGroupTag(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleOpenAddGroup = () => {
        setOpenAddNewGroup(true);
    }

    const handleAddNewGroup = () => {

        if (!groupName) {
            setGroupNameError(true);
        } else if (selected.length == 0) {
            setParametersError(true);
        } else {
            var a = [];
            var list = selected.length;
            for (var i = 0; i < list; i++) {
                var counter = selected[i];
                const grpId = counter.paramId;
                var s = { "paramId": grpId }
                a.push(s);
            }

            var reqBody = {
                groupName: groupName,
                type: groupType,
                tags: groupTag,
                parameters: a
            }

            var tenantId = secureLocalStorage.getItem("tenantId");
            var companyId = secureLocalStorage.getItem("companyId");
            ApiCalls.register("Tenant/" + tenantId + "/company/" + companyId + "/parametergroup", reqBody)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Parameter Group Added Successfully", {
                            icon: "success",
                        });
                        secureLocalStorage.setItem("currentDeviceName", null);
                        handleCloseAddGroup();
                    }
                    else if (response.data.status.details === "Already Existing Parameter Group") {
                        swal("Already Existing Parameter Group ", {
                            icon: "info",
                        });
                    }
                })
        }
    }

    const remainingOptions = selectedOptions.filter(
        (option) => !selected.some((selectedOption) => selectedOption.paramId === option.paramId)
    );

    const handleUpdateGroup = () => {
        if (!groupName) {
            setGroupNameError(true);
        } else if (selected.length == 0) {
            setParametersError(true);
        } else {
            var a = [];
            var list = selected.length;
            for (var i = 0; i < list; i++) {
                var counter = selected[i];
                const grpId = counter.paramId;
                var s = { "paramId": grpId }
                a.push(s);
            }

            var reqBody = {
                groupName: groupName,
                type: groupType == undefined ? 1 : groupType,
                tags: groupTag == undefined ? "" : groupTag,
                parameters: a
            }

            console.log(reqBody);

            var modelId = secureLocalStorage.getItem("devProfileIdtoEdit");
            var groupId = selectedGrouptoEdit.groupId

            ApiCalls.edit(`model/${modelId}/groups/${groupId}`, reqBody)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Parameter Group Updated Successfully", {
                            icon: "success",
                        });
                        handleCloseEditGroup();

                        // Fetch updated group details
                        ApiCalls.getdetails("getdevicemodelsbyid/" + modelId)
                            .then(function (response) {
                                const outData = response.data.deviceMode[0];// Assuming the response contains the updated group details
                                // Update the list of selected groups with the updated group details
                                console.log("***********");
                                console.log(outData);
                                console.log("***********");
                                console.log(outData.parameter);
                                console.log("***********");
                                setSelectedGroups(outData.parameter == undefined ? [] : outData.parameter);
                                console.log(outData.parameter[0].parameters);
                                console.log("***********");
                                // setSelectedParams(outData.parameter[0].parameters == undefined ? [] : outData.parameter[0].parameters);
                            })
                            .catch(function (error) {
                                console.error('Error fetching updated group details:', error);
                            });
                    }
                }).catch((err) => {
                    console.log(err);
                })
        }
    }

    return (
        <Container sx={{ marginTop: 5, minWidth: 800 }}>
            <CardHeader action={
                <TextField
                    label="Search Parameter Group"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            } />

            <CardContent sx={{ overflowY: "scroll", maxHeight: 400 }} >
                {
                    loadParameterGroup ? (
                        <center>
                            <CircularProgress />
                        </center>
                    ) : selectedGroups.length > 0 ?
                        <Grid container spacing={2}>
                            {selectedGroups.map((group) => (
                                <Grid item xs={12} sm={6} md={6}>
                                    <Card sx={{
                                        backgroundColor: "#F4F6F8",
                                        maxWidth: 250,
                                        boxShadow: 5
                                    }}>
                                        <CardHeader
                                            sx={{
                                                display: 'flex',
                                                justifyContent: "center"
                                            }}
                                            title={group.groupName}
                                        />
                                        <CardContent>
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                                {

                                                    <Icon icon="uim:layer-group" width="70" />
                                                }
                                            </Box>
                                        </CardContent>
                                        <CardActions>
                                            <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={(e) => handleOpenEditGroup(group)} startIcon={<Edit />}>Edit</Button>
                                            <Button variant="outlined" color="error" size="small" fullWidth onMouseDown={handleMouseDown} startIcon={<Delete />}>Delete</Button>
                                            <Tooltip title="View more">
                                                <ExpandMore
                                                    expand={expandedCards.includes(group.groupId)}
                                                    onClick={() => handleExpandClick(group.groupId)}
                                                    onMouseDown={handleMouseDown}
                                                    aria-expanded={expandedCards.includes(group.groupId)}
                                                    aria-label="show more"
                                                    color="primary"
                                                    size="large"
                                                >
                                                    <Iconify icon={'ic:round-expand-circle-down'} />
                                                </ExpandMore>
                                            </Tooltip>
                                        </CardActions>

                                        <Collapse in={expandedCards == group.groupId} timeout="auto" unmountOnExit>
                                            <>
                                                <CardContent sx={{
                                                    maxHeight: 300,
                                                    overflowY: 'scroll',
                                                }}>
                                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                        {
                                                            group.parameters && group.parameters.map((param) => (
                                                                <ListItem key={param.paramId}>
                                                                    <ListItemAvatar>
                                                                        <Iconify icon={'ic:round-dns'} />
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={param.name} secondary={param.typeName} />
                                                                </ListItem>
                                                            ))
                                                        }
                                                    </List>
                                                </CardContent>
                                            </>
                                        </Collapse>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        :
                        <Grid container spacing={2}>
                            {filteredParameterGroups.map((group) => (
                                <Grid item xs={12} sm={6} md={6}>
                                    <Card sx={{
                                        backgroundColor: "#F4F6F8",
                                        maxWidth: 250,
                                        boxShadow: 5
                                    }}>
                                        <CardHeader
                                            sx={{
                                                display: 'flex',
                                                justifyContent: "center"
                                            }}
                                            title={group.groupName}
                                        />
                                        <CardContent>
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                                {

                                                    <Icon icon="uim:layer-group" width="70" />
                                                }
                                            </Box>
                                        </CardContent>
                                        <CardActions>
                                            <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={(e) => handleOpenEditGroup(group)} startIcon={<Edit />}>Edit</Button>
                                            <Button variant="outlined" color="error" size="small" fullWidth onMouseDown={handleMouseDown} startIcon={<Delete />}>Delete</Button>
                                            <Tooltip title="View more">
                                                <ExpandMore
                                                    expand={expandedCards.includes(group.groupId)}
                                                    onClick={() => handleExpandClick(group.groupId)}
                                                    onMouseDown={handleMouseDown}
                                                    aria-expanded={expandedCards.includes(group.groupId)}
                                                    aria-label="show more"
                                                    color="primary"
                                                    size="large"
                                                >
                                                    <Iconify icon={'ic:round-expand-circle-down'} />
                                                </ExpandMore>
                                            </Tooltip>
                                        </CardActions>

                                        <Collapse in={expandedCards == group.groupId} timeout="auto" unmountOnExit>
                                            <>
                                                <CardContent sx={{
                                                    maxHeight: 300,
                                                    overflowY: 'scroll',
                                                }}>
                                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                        {
                                                            group.parameters && group.parameters.map((param) => (
                                                                <ListItem key={param.paramId}>
                                                                    <ListItemAvatar>
                                                                        <Iconify icon={'ic:round-dns'} />
                                                                    </ListItemAvatar>
                                                                    <ListItemText primary={param.name} secondary={param.typeName} />
                                                                </ListItem>
                                                            ))
                                                        }
                                                    </List>
                                                </CardContent>
                                            </>
                                        </Collapse>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                }
            </CardContent>
            <CardActions>
                <Button
                    fullWidth
                    color="error"
                    size="small"
                    type="submit"
                    variant="outlined"
                    onMouseDown={handleMouseDown}
                    onClick={handleBack}
                    sx={{
                        borderRadius: "12px",
                    }}
                    startIcon={
                        <Iconify icon={'ic:twotone-arrow-left'} />
                    }
                >
                    Previous
                </Button>
                <Button
                    fullWidth
                    color="primary"
                    size="small"
                    type="submit"
                    variant="outlined"
                    onMouseDown={handleMouseDown}
                    onClick={handleOpenAddGroup}
                    sx={{
                        borderRadius: "12px",
                    }}
                    startIcon={
                        <Iconify icon={'fluent:form-new-20-filled'} />
                    }
                >
                    Add New
                </Button>
                <Button
                    fullWidth
                    color="primary"
                    size="small"
                    type="submit"
                    variant="outlined"
                    onMouseDown={handleMouseDown}
                    onClick={onButtonSkipped}
                    sx={{
                        borderRadius: "12px",
                    }}
                    endIcon={
                        <Iconify icon={'ic:twotone-arrow-right'} />
                    }
                >
                    Next
                </Button>
            </CardActions>

            <div>
                <Dialog open={openAddNewGroup} onClose={handleCloseAddGroup}>
                    <Card sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        '@media screen and (max-height: 400px)': {
                            height: 'auto',
                        },
                    }}>
                        <CardHeader
                            title={<Typography variant="h4" gutterBottom>
                                Add Parameter Group
                            </Typography>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseAddGroup}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Box sx={{ width: 1 }}>
                                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                    <Box gridColumn="span 12">
                                        <Item>
                                            <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <TextField
                                                    name="groupName"
                                                    label="Group Name"
                                                    fullWidth
                                                    value={groupName}
                                                    onBlur={handleBlur}
                                                    onChange={handleOnChange}
                                                    error={groupNameError}
                                                    helperText={groupNameError && "Group Name is required"}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="type"
                                                        label="Type"
                                                        value={groupType}

                                                    >
                                                        <MenuItem value={1}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:heart-vitals'} />
                                                            </ListItemIcon>
                                                            Measurement
                                                        </MenuItem>
                                                        <MenuItem value={2}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:call-to-action'} />
                                                            </ListItemIcon>
                                                            Action
                                                        </MenuItem>
                                                        <MenuItem value={3}>
                                                            <ListItemIcon>
                                                                <Iconify icon={'clarity:file-settings-solid'} />
                                                            </ListItemIcon>
                                                            Settings
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormControl>
                                                    <InputLabel id="mutiple-select-label">Parameters</InputLabel>
                                                    <Select
                                                        labelId="mutiple-select-label"
                                                        label="Parameters"
                                                        name="parameters"
                                                        multiple
                                                        value={selected}
                                                        onChange={handleSelectParameters}
                                                        renderValue={(selected) => selected.map((s) => s.name).join(", ")}
                                                        MenuProps={MenuProps}
                                                        error={parametersError}
                                                    >
                                                        {selectedOptions.map((option) => (
                                                            <MenuItem key={option.paramId} value={option}>
                                                                <ListItemIcon>
                                                                    <Checkbox checked={selected.indexOf(option) > -1} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={option.name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText error={parametersError} style={{ color: parametersError ? 'red' : '' }}>
                                                        {parametersError && "Select parameters"}
                                                    </FormHelperText>
                                                </FormControl>
                                                <TextField
                                                    name="groupTag"
                                                    label="Group Tags"
                                                    fullWidth
                                                    value={groupTag}
                                                    onChange={handleOnChange}
                                                />
                                            </Stack>
                                        </Item>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                fullWidth
                                startIcon={<Close />}
                                onMouseDown={handleMouseDown}
                                onClick={handleCloseAddGroup}
                            >Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                fullWidth
                                startIcon={<Done />}
                                onMouseDown={handleMouseDown}
                                onClick={handleAddNewGroup}
                            >Submit
                            </Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>

            <div>
                <Dialog open={openEditGroup} onClose={handleCloseEditGroup}>
                    <Card sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        '@media screen and (max-height: 400px)': {
                            height: 'auto',
                        },
                    }}>
                        <CardHeader
                            title={<Typography variant="h4" gutterBottom>
                                Update Parameter Group
                            </Typography>}
                            action={
                                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleCloseEditGroup}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Box sx={{ width: 1 }}>
                                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                    <Box gridColumn="span 12">
                                        <Item>
                                            <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2 }}>
                                                <TextField
                                                    name="groupName"
                                                    label="Group Name"
                                                    fullWidth
                                                    value={groupName}
                                                    onBlur={handleBlur}
                                                    onChange={handleOnChange}
                                                    error={groupNameError}
                                                    helperText={groupNameError && "Group Name is required"}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="type"
                                                        label="Type"
                                                        value={groupType}
                                                        onChange={handleTypeChange}
                                                    >
                                                        <MenuItem value="1">
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:heart-vitals'} />
                                                            </ListItemIcon>
                                                            Measurement
                                                        </MenuItem>
                                                        <MenuItem value="2">
                                                            <ListItemIcon>
                                                                <Iconify icon={'mdi:call-to-action'} />
                                                            </ListItemIcon>
                                                            Action
                                                        </MenuItem>
                                                        <MenuItem value="3">
                                                            <ListItemIcon>
                                                                <Iconify icon={'clarity:file-settings-solid'} />
                                                            </ListItemIcon>
                                                            Settings
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormControl>
                                                    <InputLabel id="mutiple-select-label">Parameters</InputLabel>
                                                    <Select
                                                        labelId="mutiple-select-label"
                                                        label="Parameters"
                                                        name="parameters"
                                                        multiple
                                                        value={selected}
                                                        onChange={handleSelectParameters}
                                                        renderValue={(selected) => selected.map((s) => s.name).join(", ")}
                                                        MenuProps={MenuProps}
                                                        error={parametersError}
                                                    >
                                                        {selected.map((option) => (
                                                            <MenuItem key={option.paramId} value={option}>
                                                                <ListItemIcon>
                                                                    <Checkbox checked />
                                                                </ListItemIcon>
                                                                <ListItemText primary={option.name} />
                                                            </MenuItem>
                                                        ))}
                                                        {remainingOptions.map((option) => (
                                                            <MenuItem key={option.paramId} value={option}>
                                                                <ListItemIcon>
                                                                    <Checkbox />
                                                                </ListItemIcon>
                                                                <ListItemText primary={option.name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText error={parametersError} style={{ color: parametersError ? 'red' : '' }}>
                                                        {parametersError && "Select parameters"}
                                                    </FormHelperText>
                                                </FormControl>
                                                <TextField
                                                    name="groupTag"
                                                    label="Group Tags"
                                                    fullWidth
                                                    value={groupTag}
                                                    onChange={handleOnChange}
                                                />
                                            </Stack>
                                        </Item>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                fullWidth
                                startIcon={<Close />}
                                onMouseDown={handleMouseDown}
                                onClick={handleCloseEditGroup}
                            >Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                fullWidth
                                startIcon={<Done />}
                                onMouseDown={handleMouseDown}
                                onClick={handleUpdateGroup}
                            >Update
                            </Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </div>
            <div style={{
                height: "15px"
            }} />
        </Container>
    )
}

export default ParameterGroupList
