import {
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Paper
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import ApiCalls from "../../services/try";
import EditFields from './EditFields';
const EditRolemenu = ({ steps, activeStep, setActiveStep, isMedicalDomainsChecked }) => {
  const [roles, setRoles] = useState([]);
  const [mastermenu, setMastermenu] = useState([]);
  const [next, setNext] = useState(false);
  const [selectedmenu, setSelectedmenu] = useState([]);
  const [rolename, setRolename] = useState('');
  const [oldFieldName, setOldFieldName] = useState('');
  const [newFieldName, setNewFieldName] = useState('');

  const [userid, setUserid] = useState('');
  const [userId, setUserId] = useState('');
  const [roleId, setRoleId] = useState('');
  const [hide, setHide] = useState(true);
  const [appname, setAppname] = useState('');
  const [roleselect, setRoleselect] = useState('');
  const [menuList, setMenuList] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableRole, setDisableRole] = useState([]);
  const [oldName, setOldName] = useState("");
  const [newName, setNewName] = useState("");
  const [role, setRole] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [menuText, setmenuText] = useState('')
  const [rolenames, setRolenames] = useState([
    { userId: "", roleid: "3", menuText: "", oldFieldName: "FirstName", newFieldName: "", fieldType: "TextField", },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "LastName", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "DOB", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Phone", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Gender", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Email", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Street", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "State", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "City", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Country", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Pin", newFieldName: "", fieldType: "TextField" },

  ]);
  const [companyroles, setCompanyRoles] = useState([
    { userId: "", roleid: "3", menuText: "", oldFieldName: "CompanyName", newFieldName: "", fieldType: "TextField", },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Email", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Phone", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Street", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "State", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "City", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Country", newFieldName: "", fieldType: "TextField" },
    { userId: "", roleid: "3", menuText: "", oldFieldName: "Postalcode", newFieldName: "", fieldType: "TextField" },

  ]);
  const history = useHistory();
  useEffect(() => {
    const user = secureLocalStorage.getItem('userid');
    const cleanedUserId = user.replace(/['"]+/g, '');

    // Set both user IDs in the state
    setUserid(cleanedUserId);
    setUserId(cleanedUserId);

    ApiCalls.getMasterMenuList(cleanedUserId)  // Use cleanedUserId here
      .then((apiData) => {
        console.log(apiData.data);

        const menuTextValues = apiData.data.map((menu) => menu.menuText);

        // Update rolenames state with the menuText values
        setRolenames(prevRolenames => {
          return prevRolenames.map(role => ({
            ...role,
            menuText: menuTextValues.includes(role.menuText) ? role.menuText : "",
          }));
        });






        setRole(apiData.data);
      })
      .catch((error) => {
        console.error('Error fetching API data:', error);
      });
  }, []);  // Removed [userid] as it's not needed


  const handleOpen = (e, s, roleId, menuText) => {
    console.log(e)
    console.log(s)
    console.log(roleId)
    setOpen(true);
    console.log(menuText)
    setmenuText(menuText)
    setRoleId(roleId)
  };

  const handleClose = () => {
    console.log("Closing the dialog");
    console.log(open);
    setOpen(false);
  };

  const handleSelectedRolesChange = (newRoles) => {
    const updatedRoles = newRoles.map(role => ({ ...role, menuText: menuText }));

    setSelectedRoles(updatedRoles);
  };

  const handleChangePermissions = (e) => {
    const selectedMenuId = e.target.name;

    const selectedMenu = mastermenu.find(menu => menu.menuId === selectedMenuId);

    // Update the rolenames state with the corresponding menuText
    setRolenames(prevRolenames => {
      return prevRolenames.map(role => ({
        ...role,
        menuText: role.menuid === selectedMenuId ? selectedMenu.menuText : role.menuText,
      }));
    });
    const st = {
      userId: userId,
      oldFieldName: oldFieldName,
      newFieldName: newFieldName,
      menuText: menuText,
      menuid: e.target.name,
    };
    console.log("st inside handleChangePermissions");
    console.log(st);
  };

  const disable = async () => {
    console.log("disable function called");
    console.log("oldFieldName: " + rolenames);

    setDisableRole([...rolenames]);
    let abc = [...roles]; // Copy the array using spread operator

    console.log("abc inside disable");
    console.log(abc);


    console.log("selectedmenu");
    console.log(selectedmenu);





    try {
      console.log(selectedRoles)
      const rolenamesWithUserId = selectedRoles

        .filter((role) => role.checked)  // Only include items where checked is true
        .map((role) => ({ ...role, userId }));


      console.log('MenuText for API Call:');
      rolenamesWithUserId.forEach((role) => {
        console.log(role.menuText);
      });

      const response = await ApiCalls.register("ithings/" + userId + "/insertCustomFields", rolenamesWithUserId)

      console.log("response of apicall", response.data);
      // setRolenames(response.object.data);


      if (response.data.status.message === "Success") {
        // Handle success
        console.log("API call success");
        setOpen(false);
      }
    } catch (error) {
      // Handle error
      console.error('Error in API call:', error);
    }
  };

  const finishSetup = () => {
    // Add any finishing logic if needed
    history.push('/dashboard'); // Navigate to the dashboard route
  };

  const showrole = role.map(function (newrolename, index) {
    const filterIds = [2, 4, 5];

    console.log('Checking roleId:', newrolename.masterMenuId);

    if (filterIds.includes(newrolename.masterMenuId)) {
      console.log('Included:', newrolename.menuText);
      return (
        <CssBaseline>
          <Paper style={{ marginTop: "5px", backgroundColor: "#f5f5f5", width: "350px", height: "100px" }}>
            <div
              style={{
                padding: "20px",
                textAlign: "left",
                fontSize: "20px",
                color: '#607D8B',
                display: 'flex',
                justifyContent: "space-between",
                textAlign: 'center',
                marginTop: "20px",
              }}
            >
              <p style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginTop: "20px",
              }}>
                {newrolename.menuText}
              </p>
              <Button
                color='primary'
                size="medium"
                value={newrolename.customRoleName}
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e) => handleOpen(e, newrolename.customRoleName, newrolename.roleId, newrolename.menuText)}
              >
                Customize Fields
              </Button>
            </div>
          </Paper>
        </CssBaseline>
      );
    } else {
      console.log('Excluded:', newrolename.menuText);
      return null;
    }
  });

  const showmenu = mastermenu.map(function (menuText, index) {
    return (
      <div key={menuText.menuuuid} style={{
        padding: '15px',
        textAlign: 'left',
        fontSize: '20px',
      }}>
        <FormControlLabel control={
          <Checkbox
            color="primary"
            value={menuText.menuText}
            onChange={handleChangePermissions}
            defaultChecked={menuText.checked}
            name={menuText.menuId} // Set the name attribute to menuId
          />
        } label={menuText.menuText} />
      </div>
    );
  });

  return (
    <div>
      <CssBaseline />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {showrole}
          </Grid>
        </Grid>
        <br /><br />
        <Container style={{ display: 'flex', marginTop: "20px", justifyContent: "space-around" }}>
          <Button
            style={{ marginLeft: '30%' }}
            variant="contained"
            color="primary"
            onClick={() => setActiveStep(1)}
          >
            Prev
          </Button>
          <Button
            style={{ marginLeft: '20px' }}
            variant="contained"
            color="primary"
            onClick={() => {
              finishSetup();
            }}
          >
            Finish
          </Button>
        </Container>
      </Container>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <DialogContentText className='font-weight-bold text-muted'>
          </DialogContentText>
          <CardHeader title="Customize Fields" />
          <CardContent>
            {roleId == 4 ? <EditFields rolenames={companyroles} setRolenames={setCompanyRoles}  setSelectedRoles={handleSelectedRolesChange} userId={userId} /> : <EditFields rolenames={rolenames} setRolenames={setRolenames} setSelectedRoles={handleSelectedRolesChange} userId={userId} />}

          </CardContent>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress size={50} />
          ) : (
            <div>
              <Button
                color='primary'
                variant='contained'
                size='small'
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                size='small'
                style={{
                  marginLeft: '20px',
                }}
                onClick={disable}
              >
                Submit
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditRolemenu;
