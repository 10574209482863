import React, { useState } from 'react';

export const MultistepContext = React.createContext();


const Usercontext = ({ children }) => {

    const [activeStep, setActiveStep] = useState(0);


    return (
        <MultistepContext.Provider value={{ activeStep, setActiveStep }}>
            {children}
        </MultistepContext.Provider>
    )
}
export default Usercontext;