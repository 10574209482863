import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect } from "react-router-dom";

// @mui-components
import {
    Box,
    Button,
    Container,
    CssBaseline,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

//components
import Iconify from "../../components/iconify";
import Logo from '../../components/logo';

//internals
import PasswordImg from "../../assets/illustrations/authentication-amico.svg";

//services
import ApiCalls from "../../services/try";

//externals
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

//-----------------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

//-----------------------------------------------------------------------------

const ResetPassword = () => {

    const [email, setEmail] = useState('');
    const [code, setCode] = useState(['', '', '', '']);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const codeRefs = [useRef(), useRef(), useRef(), useRef()];
    const [secretCode, setSecretCode] = useState('');
    const [timeLeft, setTimeLeft] = useState('');

    const [step, setStep] = useState(1);
    const [resendCodeCount, setResendCodeCount] = useState(0);
    const [resendCodeAttempts, setResendCodeAttempts] = useState(0);

    const [isCodeSent, setIsCodeSent] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [error, setError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const [isRevealConfPassword, setIsRevealConfPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [codeExpired, setCodeExpired] = useState(false);
    const [resendCodeError, setResendCodeError] = useState(false);
    const [showCodeEntry, setShowCodeEntry] = useState(false);

    const resendCodeLimit = 3;

    const [codeExpirationTime, setCodeExpirationTime] = useState(null);

    useEffect(() => {
        if (step === 1) {
            const expirationTime = codeExpirationTime || Date.now() + 2 * 60 * 1000; // 2 minutes

            const updateTimer = () => {
                const timeDifference = expirationTime - Date.now();
                if (timeDifference > 0) {
                    const minutes = Math.floor((timeDifference / 1000) / 60);
                    const seconds = Math.floor((timeDifference / 1000) % 60);
                    setTimeLeft(`${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`);
                } else {
                    setTimeLeft('Expired');
                    setCodeExpired(true);
                }
            };

            const timerInterval = setInterval(updateTimer, 1000);

            return () => clearInterval(timerInterval);
        }
    }, [codeExpirationTime, step]);

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'email':
                    setEmailError(true);
                    break;
                case 'password':
                    setPasswordError(true);
                    break;
                case 'confirmPassword':
                    setConfirmPasswordError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'email':
                setEmailError(false);
                setEmail(event.target.value);
                break;
            case 'password':
                setPasswordError(false);
                setPassword(event.target.value);
                break;
            case 'confirmPassword':
                setConfirmPasswordError(false);
                if (event.target.value !== password) {
                    setConfirmPasswordError(true);
                }
                setConfirmPassword(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleCodeChange = (index, value) => {
        setCode(prevCode => {
            const newCode = [...prevCode];
            newCode[index] = value.slice(-1);
            return newCode;
        });

        if (value.length > 0 && index < codeRefs.length - 1) {
            codeRefs[index + 1].current.focus();
        }
    };


    const handleSendCode = (event) => {
        event.preventDefault();
        var data = {
            userName: email
        }
        const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.match(emailRegexp)) {
            ApiCalls.register("forgot-password", data)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        secureLocalStorage.setItem('email', email);
                        setIsCodeSent(true);
                        setShowCodeEntry(true); // Set showCodeEntry to true
                        // Increment the resendCodeCount
                        setResendCodeCount(prevCount => prevCount + 1);
                        // sendCode();
                    } else if (response.data.status.details === "Invalid Email Address Provided") {
                        setError(true);
                    }
                })
        } else {
            setError(true);
        }
    };

    const handleResendCode = (event) => {
        event.preventDefault();

    }

    const sendCode = () => {
        if (resendCodeCount >= 3) {
            setResendCodeError(true);
            return;
        }

        var data = {
            userName: email
        };

        ApiCalls.register("generate-code", data)
            .then(function (response) {
                console.log(response.data);
                if (response.data.statusdet.message === "Success") {
                    setSecretCode(response.data.data.code);
                    setIsCodeSent(true);
                    setShowCodeEntry(true); // Set showCodeEntry to true
                    // Increment the resendCodeCount
                    setResendCodeCount(prevCount => prevCount + 1);
                }
            });
    };

    const handleVerifyCode = (event) => {
        event.preventDefault();
        const codeText = code.join('');
        console.log(codeText); // Prints the code as a string

        ApiCalls.getdetails(`verify-code?userName=${email}&code=${codeText}`)
            .then((response) => {
                console.log(response.data);
                if (response.data == true) {
                    console.log("success");
                    setCodeError(false);
                    setStep(2); // Move to the next step
                } else {
                    console.log("invalid");
                    setCodeError(true);
                }
            })
    };

    const handleSubmit = () => {
        const data = {
            userName: email,
            newPassword: confirmPassword
        }
        ApiCalls.edit("resetPassword", data)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal("Password Changed Successfully", {
                        icon: "success",
                    });
                    setRedirect(true);
                } else {
                    swal("Password Reset", {
                        icon: "error",
                    });
                }
            })
    }

    if (redirect) {
        return (<Redirect to={'/'} />);
    }

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    if (isCodeSent) {
        return (
            <div className="container">
                <div className="forms-container">
                    <div className="signin-signup">
                        <Grid container component="main" sx={{ height: '100vh' }}>
                            <CssBaseline />
                            <Box
                                sx={{
                                    my: 8,
                                    mx: 4,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Logo
                                    sx={{
                                        position: 'fixed',
                                        top: { xs: 16, sm: 24, md: 50 },
                                        right: { xs: 16, sm: 24, md: 60 },
                                    }}
                                />
                            </Box>

                            <Container maxWidth="sm">
                                <StyledContent>
                                    {step === 1 ? (
                                        <>
                                            {codeExpired ? (
                                                <>
                                                    <Typography variant="h5" component="h1" gutterBottom>
                                                        Enter your email address
                                                    </Typography>
                                                    <TextField
                                                        label="Email *"
                                                        name="email"
                                                        type="email"
                                                        value={email}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        error={emailError}
                                                        helperText={emailError && 'Email address is required'}
                                                    />
                                                    {error ? (
                                                        <Typography variant="subtitle2" color="red">
                                                            This email address isn't registered! Try signing up.
                                                        </Typography>
                                                    ) : (
                                                        <React.Fragment />
                                                    )}

                                                    <Button
                                                        sx={{ mt: 2 }}
                                                        variant="contained"
                                                        onClick={handleResendCode}
                                                        disabled={resendCodeAttempts >= resendCodeLimit}
                                                    >
                                                        {resendCodeAttempts >= resendCodeLimit
                                                            ? 'Resend Code (Limit Exceeded)'
                                                            : 'Resend Code'}
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography variant="h5" component="h1" gutterBottom>
                                                        Enter the 4-digit code
                                                    </Typography>
                                                    {showCodeEntry && (
                                                        <div style={{ display: 'flex', justifyContent: 'center', gap: '8px', marginBottom: '5px' }}>
                                                            {code.map((digit, index) => (
                                                                <TextField
                                                                    key={index}
                                                                    value={digit}
                                                                    onChange={(event) => handleCodeChange(index, event.target.value)}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputRef={codeRefs[index]}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}

                                                    <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>
                                                        Code will expire in <span style={{ color: 'red' }}>{timeLeft}</span>
                                                    </Typography>

                                                    {codeError ? (
                                                        <Typography variant="subtitle2" color="red">
                                                            Wrong code. Try again.
                                                        </Typography>
                                                    ) : (
                                                        <React.Fragment />
                                                    )}

                                                    <Button
                                                        sx={{ mt: 2 }}
                                                        variant="contained"
                                                        onClick={handleVerifyCode}
                                                        disabled={code.some(digit => digit === '') || !showCodeEntry}
                                                    >
                                                        Verify Code
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    ) : step === 2 ? (
                                        <>
                                            <Typography variant="h5" component="h1" gutterBottom>
                                                Set a new Password
                                            </Typography>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '8px' }}>
                                                <TextField
                                                    label="New Password *"
                                                    name="password"
                                                    type={isRevealPassword ? 'text' : 'password'}
                                                    value={password}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    error={passwordError}
                                                    helperText={passwordError && "Password is required"}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton color="primary" onClick={() => setIsRevealPassword(!isRevealPassword)} onMouseDown={(e) => e.preventDefault()} edge="end">
                                                                    <Iconify icon={isRevealPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    label="Confirm Password *"
                                                    name="confirmPassword"
                                                    type={isRevealConfPassword ? 'text' : 'password'}
                                                    value={confirmPassword}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    error={confirmPasswordError}
                                                    helperText={confirmPasswordError && "Password must match"}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton color="primary" onClick={() => setIsRevealConfPassword(!isRevealConfPassword)} onMouseDown={(e) => e.preventDefault()} edge="end">
                                                                    <Iconify icon={isRevealConfPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                            <Button sx={{
                                                mt: 3
                                            }} variant="contained" onClick={handleSubmit} onMouseDown={handleMouseDown} >
                                                Reset Password
                                            </Button>

                                        </>
                                    ) : null}
                                </StyledContent>
                            </Container>
                        </Grid>
                    </div>
                </div>

                <div class="panels-container">
                    <div class="panel left-panel">
                        <div class="content">
                            <h3>
                                Sign in to IoMT
                            </h3>
                            <p>
                                Remember Password?
                            </p>
                            <Link to="/">
                                <button class="btn transparent" id="sign-up-btn">
                                    Sign In
                                </button>
                            </Link>
                        </div>
                        <img src={PasswordImg} class="image" alt="" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="forms-container">
                <div className="signin-signup">
                    <Grid container component="main" sx={{ height: '100vh' }}>
                        <CssBaseline />
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Logo
                                sx={{
                                    position: 'fixed',
                                    top: { xs: 16, sm: 24, md: 50 },
                                    right: { xs: 16, sm: 24, md: 60 },
                                }}
                            />
                        </Box>

                        <Container maxWidth="sm">
                            <StyledContent>
                                <Typography variant="h5" component="h1" gutterBottom>
                                    Enter your email address
                                </Typography>
                                <TextField
                                    label="Email *"
                                    name="email"
                                    type="email"
                                    value={email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    error={emailError}
                                    helperText={emailError && "Email address is required"}
                                />
                                {error ? (
                                    <Typography variant="subtitle2" color="red">
                                        This Email address isn't registered! Try SignUp
                                    </Typography>
                                ) : (
                                    <React.Fragment />
                                )}
                                <Button sx={{
                                    mt: 2
                                }} variant="contained" onClick={handleSendCode}>
                                    Send Code
                                </Button>
                            </StyledContent>
                        </Container>
                    </Grid>
                </div>
            </div>

            <div className="panels-container">
                <div className="panel left-panel">
                    <div className="content">
                        <h3>Sign in to IoMT</h3>
                        <p>Remember Password?</p>
                        <Link to="/">
                            <button className="btn transparent" id="sign-up-btn">
                                Sign In
                            </button>
                        </Link>
                    </div>
                    <img src={PasswordImg} className="image" alt="" />
                </div>
            </div>
        </div>
    );

}

export default ResetPassword;
