import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import { Delete, Edit, Home, Visibility } from '@mui/icons-material';
import {
    Avatar,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from '../../services/try';

//internals
import DeviceImg from "../../assets/images/devices.png";

//externals
import { Icon } from '@iconify/react/dist/iconify.js';
import moment from 'moment';
import secureLocalStorage from "react-secure-storage";
import swal from 'sweetalert';

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

// ----------------------------------------------------------------------

const ManageDevice = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openDeviceHistory, setOpenDeviceHistory] = useState(false);

    const [device, setDevice] = useState([]);
    const [selectedDeviceHistorytoView, setSelectedDeviceHistorytoView] = useState([]);
    const [selectedDevicetoView, setSelectedDevicetoView] = useState([]);

    const [viewMode, setViewMode] = useState('list');
    const [searchTerm, setSearchTerm] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const loadDeviceList = () => {
        setLoading(true);
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("UUID");
            physicianId = "0";
        } else if (rolename === "Physician") {
            tenantId = secureLocalStorage.getItem("tenantId");
            companyId = secureLocalStorage.getItem("compId");
            physicianId = secureLocalStorage.getItem("UUID");
        }
        console.log(companyId, '********************');
        ApiCalls.getdetails("PhysicianId/" + tenantId + "/company/" + companyId + "/showdevice")
            .then((response) => {
                console.log(response.data.device);
                if (response.data.device != undefined) {
                    setDevice(response.data.device);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setDevice([]);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                setDevice([]);
            })
    }

    useEffect(() => {
        loadDeviceList();
    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleChangeViewMode = (event, newViewMode) => {
        if (newViewMode !== null) {
            setViewMode(newViewMode);
        }
    };

    const filteredDevices = device.filter((device) =>
        device.devicename && device.devicename.toLowerCase().includes(searchTerm.toLowerCase()) ||
        device.randomDeviceId && device.randomDeviceId.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const handleDeleteDevice = (device) => {
        console.log(device.devcompstatus);

        if (device.devcompstatus === "active") {
            swal({
                title: "Cannot Delete",
                text: `This ${secureLocalStorage.getItem("DeviceName")} cannot be deleted at the moment as they are currently Enrolled to a ${secureLocalStorage.getItem("CompanyName")}`,
                icon: "warning",
                buttons: {
                    ok: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "btn-danger",
                        closeModal: true,
                    },
                },
                dangerMode: true,
            });
        } else {
            var deviceId = device.deviceuuid;
            ApiCalls.getdetails("device/" + deviceId + "/checkForConnectedBeds")
                .then((response) => {
                    console.log(response.data);
                    if (response.data == true) {
                        swal({
                            title: "Cannot Delete",
                            text: `This ${secureLocalStorage.getItem("DeviceName")} cannot be deleted at the moment as they are currently connected.`,
                            icon: "warning",
                            buttons: {
                                ok: {
                                    text: "OK",
                                    value: true,
                                    visible: true,
                                    className: "btn-danger",
                                    closeModal: true,
                                },
                            },
                            dangerMode: true,
                        });
                    } else {
                        swal({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!!",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        })
                            .then((willDelete) => {
                                if (willDelete) {
                                    var rolename = secureLocalStorage.getItem("rolename");
                                    if (rolename === "Tenant") {
                                        ApiCalls.delete("deletedevices/" + deviceId)
                                            .then((response) => {
                                                console.log(response.data);
                                                if (response.data.status.message === "Success") {
                                                    swal("Deleted Successfully!", {
                                                        icon: "success",
                                                    });
                                                    loadDeviceList();
                                                }
                                            });
                                    } else if (rolename === "Company") {
                                        ApiCalls.delete("deletedevicesByCompany/" + deviceId)
                                            .then((response) => {
                                                console.log(response.data);
                                                if (response.data.status.message === "Success") {
                                                    swal("Deleted Successfully!", {
                                                        icon: "success",
                                                    });
                                                    loadDeviceList();
                                                }
                                            });
                                    }

                                }
                            });
                    }
                })
        }
    }

    const handleEditDevice = (device) => {
        secureLocalStorage.setItem("deviceIdToEdit", device.decmacid);
        secureLocalStorage.setItem("deviceIdSelected", device.deviceuuid);
        setRedirectToEdit(true);
    }

    const handleClickDevice = (deviceDetails) => {
        console.log(deviceDetails.decmacid);
        secureLocalStorage.setItem("deviceIdToEdit", deviceDetails.decmacid)
        secureLocalStorage.setItem("deviceIdSelected", deviceDetails.deviceuuid);
        setSelectedDevicetoView(deviceDetails);
        setOpenDeviceHistory(true);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const maskDeviceId = (randomDeviceId) => {
    //     if (!randomDeviceId) return "N/A"; // Handle case where randomDeviceId is undefined or null

    //     const prefix = randomDeviceId.substring(0, 4);
    //     const suffix = randomDeviceId.substring(randomDeviceId.length - 6);

    //     // Join prefix, maskedChars with "-", and suffix
    //     return `${prefix}xxxx-xxxx-xxxx-xxxx-${suffix}`;
    // }


    if (openDeviceHistory) {
        return (<Redirect to={'/devicehistory'} />);
    }

    if (redirectToEdit) {
        return (<Redirect to={'/editdevice'} />)
    }

    if (redirectToForm) {
        return (<Redirect to={'/registerdevice'} />)
    }


    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>


                        <StyledBreadcrumb label={"Manage " + secureLocalStorage.getItem("DeviceName")} />
                    </Breadcrumbs>
                    <Button sx={{
                        display: secureLocalStorage.getItem("rolename") === "Physician" ? "none" : ""
                    }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setRedirectToForm(true)}>
                        New {secureLocalStorage.getItem("DeviceName")}
                    </Button>
                </Stack>


                <Card>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {secureLocalStorage.getItem("DeviceName") + " List"}
                        </Typography>}

                        action={
                            <Stack direction="row" spacing={2} >
                                {/* Button: Total Records */}
                                <Button className="ml-2" color='primary' variant='outlined' onMouseDown={handleMouseDown} sx={{
                                    cursor: "text"
                                }}>
                                    {loading ? <Icon icon={'eos-icons:three-dots-loading'} width="30" /> : (`Total ${secureLocalStorage.getItem("DeviceName")}: ${device.length}`)}
                                </Button>
                                <TextField
                                    label={"Search " + secureLocalStorage.getItem("DeviceName")}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <ToggleButtonGroup
                                    size="small"
                                    value={viewMode}
                                    exclusive
                                    onMouseDown={handleMouseDown}
                                    onChange={handleChangeViewMode}
                                    aria-label="view mode"
                                >
                                    <Tooltip title="List View">
                                        <ToggleButton value="list" aria-label="list">
                                            <Icon icon={'ion:list'} width="20" />
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title="Grid View">
                                        <ToggleButton value="grid" aria-label="grid">
                                            <Icon icon={'material-symbols:grid-view-rounded'} width="20" />
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            </Stack>
                        }
                    />
                    <CardContent>
                        {
                            loading ? (
                                <center>
                                    <CircularProgress />
                                </center>
                            ) : filteredDevices.length > 0 ? (

                                viewMode === 'list' ?
                                    <TableContainer TableContainer component={Paper}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                        {secureLocalStorage.getItem("DeviceName") + " Name"}
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>UUID</TableCell>
                                                    <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>OS Version</TableCell>
                                                    <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Registered Date</TableCell>
                                                    {secureLocalStorage.getItem("roleId") == 3 && (
                                                        <>
                                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Enrolled To</TableCell>
                                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Enrolled Date</TableCell>
                                                        </>
                                                    )}
                                                    <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Model Name</TableCell>
                                                    <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredDevices.map((device) => (
                                                    <TableRow key={device.deviceuuid} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f4f4f4' } }}>
                                                        <TableCell sx={{ textAlign: 'center' }}>{device.devicename}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{device.randomDeviceId}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{device.osversion}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>
                                                            {moment(device.measTimeStamp, 'x').utc().format('YYYY-MM-DDTHH:mm:ss[Z]')}
                                                        </TableCell>
                                                        {secureLocalStorage.getItem("roleId") == 3 && (
                                                            <>
                                                                <TableCell sx={{ textAlign: 'center' }}>
                                                                    {device.devcompstatus === "active" ? device.companyname : "--"}
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: 'center' }}>
                                                                    {device.devcompstatus === "active"
                                                                        ? moment(device.enrolledDate, 'YYYY-MM-DD HH:mm:ss.SSS').utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
                                                                        : "--"
                                                                    }
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        <TableCell sx={{ textAlign: 'center' }}>
                                                            {device.modelSchema == undefined ? "" : device.modelSchema[0].modelName}
                                                        </TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>
                                                            <IconButton color="primary" size="small" onMouseDown={handleMouseDown} onClick={() => handleEditDevice(device)}>
                                                                <Edit fontSize="small" />
                                                            </IconButton>
                                                            <IconButton color="error" size="small" onMouseDown={handleMouseDown} onClick={() => handleDeleteDevice(device)}>
                                                                <Delete fontSize="small" />
                                                            </IconButton>
                                                            <IconButton color="primary" size="small" onMouseDown={handleMouseDown} onClick={() => handleClickDevice(device)}>
                                                                <Visibility fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            component="div"
                                            count={device.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                                marginTop: '10px',
                                                '& .MuiTablePagination-toolbar': {
                                                    alignItems: 'center',
                                                },
                                                '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                },
                                                '& .MuiButtonBase-root': {
                                                    outline: 'none',
                                                    '&:focus': {
                                                        outline: 'none',
                                                        boxShadow: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                    </TableContainer> :

                                    <Grid container spacing={2}>
                                        {filteredDevices.map((devices) => (
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Card sx={{
                                                    maxWidth: 320,
                                                    boxShadow: 3,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center', // Center items horizontally
                                                    '&:hover': {
                                                        border: '1px solid #D1E9FC',
                                                        boxShadow: 'none'
                                                    }
                                                }}>
                                                    <CardHeader titleTypographyProps={{ variant: 'subtitle2' }} title={devices.devicename} />
                                                    <Avatar
                                                        variant='rounded'
                                                        alt={devices.devicename}
                                                        src={(devices.deviceimageName && devices.deviceimageName !== "null") ? devices.deviceimageName : DeviceImg}
                                                        sx={{
                                                            width: 150,
                                                            height: 150,
                                                            objectFit: 'contain',
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => handleClickDevice(devices)}
                                                    />
                                                    <CardContent
                                                        sx={{
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => handleClickDevice(devices)}
                                                    >
                                                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                            UUID : {devices.randomDeviceId}
                                                        </Typography>


                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary', mt: 1 }}>
                                                            Model Name : {devices.modelSchema == undefined ? "" : devices.modelSchema[0].modelName}
                                                        </Typography>
                                                        {devices.modelSchema !== undefined && (
                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                Model Number: {devices.modelSchema[0]?.modelNumber || "Not provided"}
                                                            </Typography>
                                                        )}
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleEditDevice(devices)} startIcon={<Edit />}>Edit</Button>
                                                        <Button variant="outlined" color="error" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleDeleteDevice(devices)} startIcon={<Delete />}>Delete</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>

                            ) : (
                                <center>
                                    No {secureLocalStorage.getItem("DeviceName")} Found
                                </center>
                            )
                        }

                    </CardContent>
                </Card>

            </Container>
        </div >
    )
}

export default ManageDevice
