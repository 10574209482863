import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//internals
import QRCode from 'qrcode.react';
import Preview from "../../layout/Preview";
import './QRCodeScanner.css';

//mui-components
import { Home } from '@mui/icons-material';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    ClickAwayListener,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    MenuList,
    Paper,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from '@iconify/react';
import uniqueId from 'lodash/uniqueId';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { QrReader } from 'react-qr-reader';
import secureLocalStorage from 'react-secure-storage';
import swal from "sweetalert";

//----------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const StyledPre = styled('pre')({
    // your styles here
});

//-----------------------------------------------------------------------------

const AddDevice = () => {

    const [companies, setCompanies] = useState([]);
    const [deviceProfiles, setDeviceProfiles] = useState([]);
    const [patientstoAssign, setPatientstoAssign] = useState([]);
    const [filteredDeviceDetails, setFilteredDeviceDetails] = useState([]);
    const [deviceDetails, setDeviceDetails] = useState([]);

    const [id] = useState(uniqueId('prefix-'));
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedDeviceProfile, setSelectedDeviceProfile] = useState('');
    const [name, setName] = useState('');
    const [macId, setMacId] = useState('');
    const [devImg, setDevImg] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [savedDeviceId, setSavedDeviceId] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [androidId, setAndroidId] = useState('');
    const [osVersion, setOsVersion] = useState('');
    const [device, setDevice] = useState('');
    const [randomDeviceId, setRandomDeviceId] = useState('');
    const [passphrase, setPassphrase] = useState('');
    const [searchBoxId, setSearchBoxId] = useState('');

    const [devicesDetails, setDevicesDetails] = useState({});

    const [selectedPatientToAssign, setSelectedPatientToAssign] = useState(null);
    const [authenticationStatus, setAuthenticationStatus] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);

    const [openPopup, setOpenPopup] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [deviceProfileError, setDeviceProfileError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [macIdError, setMacIdError] = useState(false);
    const [showing, setShowing] = useState(false);
    const [generateBtnEnable, setGenerateBtnEnable] = useState(true);
    const [assignBtnEnable, setAssignBtnEnable] = useState(true);
    const [cancelBtnEnable, setCancelBtnEnable] = useState(true);
    const [redirectToList, setRedirectToList] = useState(false);
    const [openPatientList, setOpenPatientList] = useState(false);
    const [loadPatientList, setLoadPatientsList] = useState(false);
    const [hideCompany, setHideCompany] = useState(true);
    const [hidePhysician, setHidePhysician] = useState(true);
    const [showScanner, setShowScanner] = useState(false);
    const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
    const [isDeviceEditable, setIsDeviceEditable] = useState(false);
    const [androidIdError, setAndroidIdError] = useState(false);
    const [osVersionError, setOsVersionError] = useState(false);
    const [deviceError, setDeviceError] = useState(false);
    const [searched, setSearched] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const registerButtonRef = useRef(null);
    const [roleId, setRoleId] = useState(null)
    const deviceNameRef = useRef(null);




    const fetchRandomDeviceIds = () => {
        // Retrieve tenantId from secureLocalStorage
        var tenantId = secureLocalStorage.getItem("tenantId");

        // Ensure tenantId is not null or undefined
        if (tenantId !== null && tenantId !== undefined) {
            // Make API call including searchQuery and tenantId
            ApiCalls.getdetails(`device/randomDeviceIds?searchBoxId=${searchQuery}&physicianId=${tenantId}`)
                .then(response => {
                    console.log(response.data);
                    console.log("Tenant ID:", tenantId);

                    if (response.data.deviceDetails != undefined) {
                        setDeviceDetails(response.data.deviceDetails);
                    } else {
                        setDeviceDetails([]);
                    }
                })
                .catch(error => {
                    console.error('Error fetching random device IDs:', error);
                    console.error('Tenant ID is null or undefined');
                    setDeviceDetails([]);
                });
        }
    };


    useEffect(() => {
        fetchRandomDeviceIds();
    }, [searchQuery]);

    useEffect(() => {
        const filteredDetails = deviceDetails.filter(detail =>
            detail.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredDeviceDetails(filteredDetails);
    }, [deviceDetails, searchQuery]);

    const handleSearchQueryChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        // Show suggestions only if there is input in the search field
        setShowSuggestions(query.trim().length > 0);
    };

    const handleMenuItemClick = (deviceDetail) => {
        setSearchQuery(deviceDetail);
        setShowSuggestions(false);
    };

    const handleClickAway = () => {
        setShowSuggestions(false);
    };

    const handleSearch = () => {
        setDevicesDetails({});
        setSearched(false);

        if (searchQuery.trim() !== '') {
            const apiUrl = `devices/${searchQuery}`;

            ApiCalls.getdetails(apiUrl)
                .then(response => {
                    if (response.data !== undefined) {
                        setDevicesDetails(response.data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching device details:', error);
                    setDevicesDetails({});
                    setSearched(true);
                });
        } else {
            setDevicesDetails({});
            setSearched(true);
        }
    };



    const handleGenerateQRCode = (randomDeviceId) => {
        // setOpenPopup(true);
        var hospitalid = secureLocalStorage.getItem("UUID")
        fetchDetailsForQrCode(randomDeviceId, hospitalid);

    };

    const fetchDetailsForQrCode = (randomdeviceid, hospitalid) => {
        var server = "test"
        ApiCalls.getdetails(`randomdeviceid/${randomdeviceid}/hospitalid/${hospitalid}/server/${server}/getqrcodecontents`)

            .then((response) => {
                console.log(response.data);
                console.log(secureLocalStorage.getItem("UUID"));
                if (response.data != undefined) {
                    setApiResponse({
                        ...response.data,
                        // compId: secureLocalStorage.getItem("UUID"),
                    });
                    setOpenPopup(true);
                } else {
                    setOpenPopup(false);
                }
            })
            .catch((error) => {
                console.log('Error fetching details for QR code:', error);
                // setApiResponse(
                //     {
                //         "qrtoken": "TRsAoi00p7a8jAXDZXGhn4XzLffOBkiakfOTo5Qb9r3be/d5urwKI+fCt2n8WHvKG5q691B6cdCaTUe47MyHh8lVYO0Z5Rt5CMUuC+9sHLWMstmpwQE+0kyjKm59RZDLgMxsI2ETsrL0aqitEffKdHHrMkewIxPwsvnTOhKopYTD2WNITqjLSROy/vbxTC8+QZuazLCN1PkTUoZLQFx5/LLWGI70YCAa9145U6W2HjbscHL7lv6NrWb9IsX642WbR9AJpmBmWNYj2A4dYk7l7X7TqIsEAnq55O4T0Td62Q8tYTTcUaj/ucj4RiTYtXaphUXEMHklER61IZKW36p67w=="
                //     }
                // )
                // setOpenPopup(true);

            });
    };

    // const maskDeviceId = (randomDeviceId) => {
    //     if (!randomDeviceId) return ""; // Handle case where randomDeviceId is undefined or null

    //     const prefix = randomDeviceId.substring(0, 4);
    //     const suffix = randomDeviceId.substring(randomDeviceId.length - 6);

    //     // Join prefix, maskedChars with "-", and suffix
    //     return `${prefix}xxxx-xxxx-xxxx-xxxx-${suffix}`;
    // }

    useEffect(() => {
        if (isDeviceEditable) {
            deviceNameRef.current.focus();
        }
    }, [isDeviceEditable]);

    const handleScan = (data) => {
        if (data) {
            var encryptedText = data.text;
            ApiCalls.post("decryptData", encryptedText)
                .then((response) => {
                    if (response.data.status.message === "Success") {
                        const qrDataText = response.data.status.details;
                        console.log(qrDataText);

                        if (qrDataText) {
                            // Split the data into separate lines
                            const qrDataArray = qrDataText.trim().split('\n');

                            // Initialize variables to store extracted values
                            let androidId, osVersion, device, randomDeviceId, passphrase;

                            // Loop through the qrDataArray to extract values
                            qrDataArray.forEach(item => {
                                // Split each item into key and value
                                const [key, value] = item.split(':');

                                // Trim key and value to remove any extra spaces
                                const trimmedKey = key.trim();
                                const trimmedValue = value.trim();

                                // Store the value in the appropriate variable based on the key
                                switch (trimmedKey) {
                                    case 'Android ID':
                                        androidId = trimmedValue;
                                        break;
                                    case 'OS Version':
                                        osVersion = trimmedValue;
                                        break;
                                    case 'Device':
                                        device = trimmedValue;
                                        break;
                                    case 'UUID':
                                        randomDeviceId = trimmedValue;
                                        break;
                                    case 'Passphrase':
                                        passphrase = trimmedValue;
                                        break;
                                    default:
                                        break;
                                }
                            });

                            // Now you have extracted values stored in androidId, osVersion, device, randomDeviceId, passphrase variables
                            // console.log('Android ID:', androidId);
                            // console.log('OS Version:', osVersion);
                            // console.log('Device:', device);
                            // console.log('UUID:', maskDeviceId(randomDeviceId)); // Masked UUID
                            // console.log('Passphrase:', passphrase);

                            // Now you have extracted values stored in androidId, osVersion, device, randomDeviceId, passphrase variables
                            var tenantId = secureLocalStorage.getItem("UUID");
                            ApiCalls.getdetails("tenant/" + tenantId + "/device/" + randomDeviceId + "/checkForExistingDevices")
                                .then((response) => {
                                    if (response.data.details === "Device Already Enrolled") {
                                        // Show error popup
                                        showErrorPopup(secureLocalStorage.getItem("DeviceName") + " Already Enrolled at another " + secureLocalStorage.getItem("CompanyName"));
                                    } else if (response.data.details === "Device Already Registered") {
                                        showErrorPopup(secureLocalStorage.getItem("DeviceName") + "  Already Registered");
                                    } else {
                                        // Set device details in state
                                        setAndroidId(androidId);
                                        setOsVersion(osVersion);
                                        setDevice(device);
                                        setRandomDeviceId(randomDeviceId);
                                        setPassphrase(passphrase);
                                        setIsNextButtonEnabled(true);
                                        setIsDeviceEditable(true); // Allow editing Device Name
                                        // setShowScanner(false); // Hide the scanner on successful data fetch
                                    }
                                })
                        } else {
                            console.error("qrDataText is undefined or empty.");
                            // Handle undefined or empty qrDataText (e.g., show error popup)
                        }
                    }
                })
        }
    }

    const showErrorPopup = (message) => {
        // Implement your popup component or dialog box to show the error message
        swal(message, {
            icon: "warning",
            timer: 1500,
        });
    };

    const handleError = (err) => {
        console.error('Error:', err);
    };

    // Function to handle Next button click
    const handleNextButtonClick = () => {
        const data = {
            devicename: device,
            modelid: selectedDeviceProfile,
            devmacid: androidId,
            osversion: osVersion,
            randomDeviceId: randomDeviceId,
            passphrase: passphrase,
            tenantid: secureLocalStorage.getItem("tenantId")
        };

        console.log(data);

        ApiCalls.register("registeribsmdevice", data)
            .then((response) => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal("Success", {
                        icon: "success",
                        timer: 1000,
                    });
                    // Clear text field values
                    setOsVersion('');
                    setDevice('');
                    setRandomDeviceId('');

                    // Disable Next button
                    setIsNextButtonEnabled(false);
                    setIsDeviceEditable(false);
                    // setShowScanner(true); // Show the scanner on clicking next
                }
            }).catch((error) => {
                console.log(error);
            })

    };

    // Add a keydown event listener to the input fields for Android ID, OS Version, and Device Name
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && (roleId == 3 || roleId == "3")) {
                // Trigger handleNextButtonClick when Enter key is pressed
                handleNextButtonClick();
            }
        };

        // Attach event listener to the document
        document.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleNextButtonClick]);


    useEffect(() => {
        var tenanitid = secureLocalStorage.getItem("tenantId");
        ApiCalls.getdetails("organizations/0/tenants/" + tenanitid + "/companies")
            .then((response) => {
                console.log(response.data);
                const options = response.data.companies.map(d => ({
                    "value": d.companyuuid,
                    "label": d.name
                }))
                setCompanies(options);
            })

        const roleId = secureLocalStorage.getItem("roleId");
        console.log(roleId);
        setRoleId(roleId)
        const userId = secureLocalStorage.getItem("tenantMailId");
        ApiCalls.getRoleBasedMenu(userId, roleId)
            .then(function (response) {
                console.log(response.data);
                const menuArray = response.data;

                const hasManageCompany = menuArray.some(item => item.url === '/managecustomer');
                const hasManagePhysician = menuArray.some(item => item.url === '/managetherapist');

                if (!hasManageCompany) {
                    setHideCompany(true);
                } else {
                    setHideCompany(false);
                }

                if (!hasManagePhysician) {
                    setHidePhysician(true);
                } else {
                    setHidePhysician(false);
                }

            })

        getDeviceProfileList();
        getPatienttoAssignList();
    }, [])

    const getPatienttoAssignList = () => {
        let tenantId = secureLocalStorage.getItem("tenantId");
        let companyId = secureLocalStorage.getItem("compId");
        let physicianId = "";
        let rolename = secureLocalStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureLocalStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = "0";
            companyId = secureLocalStorage.getItem("UUID");
            physicianId = "0";
        } else if (rolename === "Physician") {
            tenantId = "0";
            companyId = "0";
            physicianId = secureLocalStorage.getItem("UUID");
        }
        ApiCalls.getdetails(
            "organizations/0/tenants/" +
            tenantId +
            "/companies/" +
            companyId +
            "/physicians/" +
            physicianId +
            "/patients?"
        )
            .then((result) => {
                console.log(result.data);
                if (result.data.patient !== undefined) {
                    for (let i = 0; i < result.data.patient.length; i++) {
                        setPatientstoAssign(result.data.patient);
                        setLoadPatientsList(false);
                    }
                } else {
                    setPatientstoAssign([]);
                    setLoadPatientsList(false);
                }
            })
            .catch((error) => {
                console.log("error: " + error);
                setPatientstoAssign([]);
                setLoadPatientsList(false);
            });
    }

    const getDeviceProfileList = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        ApiCalls.getdetails("tenants/" + tenantId + "/companies/0/physicians/0/devicemodelslist")
            .then(function (response) {
                console.log(response.data);
                const options = response.data.deviceMode.map(d => ({
                    "value": d.deviceUUid,
                    "label": d.modelName,
                    "qrenabled": d.qrEnabled
                }))
                setDeviceProfiles(options);
                console.log(options);
                const activeProfile = options.find(profile => profile.qrenabled === "active");
                console.log(activeProfile);

                // If an active profile is found, set its value as selectedDeviceProfile
                if (activeProfile) {
                    setSelectedDeviceProfile(activeProfile.value);
                    // Call the function to check authentication status
                    // checkAuthenticationStatus(activeProfile.value);
                }
            })
    }

    // const checkAuthenticationStatus = (modelId) => {
    //     const apiUrl = `modelid/${modelId}/checkauthenticationenabled`;

    //     ApiCalls.getdetails(apiUrl)
    //         .then((response) => {
    //             console.log(`Authentication status for model ${modelId}:`, response.data);
    //             setAuthenticationStatus(response.data);

    //             // If authentication is successful, show the QR code scanner
    //             if (response.data && (secureLocalStorage.getItem("roleId") === "4" || secureLocalStorage.getItem("roleId") == 4)) {
    //                 // Call the function to check authentication status
    //                 checkAuthenticationStatus(selectedDeviceProfile);
    //             }

    //         })
    //         .catch((error) => {
    //             console.error(`Error checking authentication status for model ${modelId}:`, error);
    //             // Handle the error as needed
    //         });
    // };

    const handleBlur = (event) => {
        if (!event.target.value && !hideCompany) {
            switch (event.target.name) {
                case 'company':
                    setCompanyError(true);
                    break;
                case 'profile':
                    setDeviceProfileError(true);
                    break;
                case 'name':
                    setNameError(true);
                    break;
                case 'macId':
                    setMacIdError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleClosePopup = () => {
        // Close the popup when needed (e.g., when the user clicks outside the popup)
        setOpenPopup(false);
        // setRedirectToList(true);
    };

    const getDeviceProfileByName = (deviceProfileId) => {
        console.log(deviceProfileId);
        ApiCalls.getdetails("getdevicemodelsbyid/" + deviceProfileId)
            .then((response) => {
                console.log("Model Details");
                console.log("**********************");
                console.log(response.data);
                console.log("Model Count =" + response.data.deviceMode.length);
                console.log("Model Id=" + response.data.deviceMode[0].deviceUUid);
                secureLocalStorage.setItem("modelId", response.data.deviceMode[0].deviceUUid);
                setShowing(true);
                setGenerateBtnEnable(false);
                setDeviceId({ id });
                setDevImg(response.data.deviceMode[0].devImage);
            })
    }

    const handleClosePatientList = () => {
        setOpenPatientList(false);
    }

    const handleSelectPatientToAssign = (event) => {
        const patientId = event.target.value;
        if (patientId === selectedPatientToAssign) {
            // User is unchecking the checkbox
            setSelectedPatientToAssign(null);
        } else {
            // User is checking a different checkbox
            setSelectedPatientToAssign(patientId);
        }
    }

    const handleAssignDevicetoPatient = () => {
        console.log(selectedPatientToAssign);
        console.log(savedDeviceId);

        var data = {
            patients: [
                {
                    patientuuid: selectedPatientToAssign
                }
            ]
        }

        ApiCalls.register("device/" + savedDeviceId + "/patient/assign", data)
            .then((response => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal((secureLocalStorage.getItem("PatientName") + " Assigned"), {
                        icon: "success",
                    });
                    handleClosePatientList();
                    setRedirectToList(true);
                } else if (response.data.status.details === "Device Already Assigned To The Patient") {
                    swal((secureLocalStorage.getItem("PatientName") + " Already Assigned"), {
                        icon: "warning",
                    });
                }
            })).catch((error) => {
                console.log(error.message);
            });
    }

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    if (redirectToList) {
        return (<Redirect to={"/managedevice"} />)
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <Link to="/managedevice">
                            <Button variant="text" startIcon={<Icon icon={'tabler:devices-plus'} fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                {"Manage " + secureLocalStorage.getItem("DeviceName")}
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Add " + secureLocalStorage.getItem("DeviceName")} />
                    </Breadcrumbs>

                </Stack>

                <Card sx={{
                    minWidth: 300,
                }}>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {"Add " + secureLocalStorage.getItem("DeviceName")}
                        </Typography>}
                    />

                    {secureLocalStorage.getItem("roleId") === "3" && (
                        <CardContent>
                            <Box sx={{ width: '100%' }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Stack direction="column" spacing={2} justifyContent="space-between" sx={{ my: 2, display: hideCompany ? "none" : "block" }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Select Device Profile</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name="profile"
                                                    label="Select Device Profile"
                                                    value={selectedDeviceProfile}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        setSelectedDeviceProfile(selectedValue);
                                                        setDeviceProfileError(false);
                                                        getDeviceProfileByName(selectedValue);
                                                    }}

                                                    error={deviceProfileError}
                                                >
                                                    {deviceProfiles.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                                <FormHelperText error={deviceProfileError} style={{ color: deviceProfileError ? 'red' : '' }}>
                                                    {deviceProfileError && "Select a Device Profile"}
                                                </FormHelperText>
                                            </FormControl>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {secureLocalStorage.getItem("roleId") === "3" && ( // Check if the role ID is 3
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onMouseDown={handleMouseDown}
                                                        onClick={() => setShowScanner(true)}
                                                        endIcon={<Icon icon={'tabler:line-scan'} />}
                                                        sx={{ borderRadius: 2 }}
                                                    >
                                                        Start QR Scan
                                                    </Button>
                                                )}
                                            </div>

                                            <hr style={{ borderTop: '2px dashed grey', width: '100%', margin: '30px 0 2px 0', fontWeight: 'bold' }} />

                                            <TextField
                                                label={
                                                    <span>
                                                        OS Version{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                variant="outlined"
                                                fullWidth
                                                value={osVersion}
                                                InputProps={{
                                                    readOnly: true,
                                                }} // Add this prop to make it read-only
                                            />

                                            <TextField
                                                label={
                                                    <span>
                                                        {secureLocalStorage.getItem("DeviceName") + " Name"}{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                variant="outlined"
                                                fullWidth
                                                value={device}
                                                onChange={(e) => setDevice(e.target.value)}
                                                InputProps={{
                                                    readOnly: !isDeviceEditable, // Make the text field editable based on isDeviceEditable state
                                                }}
                                                inputRef={deviceNameRef} // Assign the ref to the TextField
                                            />

                                            <TextField
                                                label={
                                                    <span>
                                                        {secureLocalStorage.getItem("DeviceName") + " UUID"}{' '}
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </span>
                                                }
                                                variant="outlined"
                                                fullWidth
                                                value={randomDeviceId}
                                                InputProps={{
                                                    readOnly: true,
                                                }} // Add this prop to make it read-only
                                            />

                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleNextButtonClick()}
                                                    disabled={!isNextButtonEnabled}
                                                    endIcon={<Icon icon={'material-symbols:double-arrow'} width={25} />}
                                                    sx={{ borderRadius: 2 }}>
                                                    Save & Next
                                                </Button>
                                            </div>
                                        </Stack>

                                    </div>

                                    <div className="col-md-6">
                                        {secureLocalStorage.getItem("roleId") === "3" && ( // Check if the role ID is 3
                                            <div className="qr-scanner-container">
                                                {showScanner ? (
                                                    <div className="qr-scanner-window">
                                                        <QrReader onResult={handleScan} onError={handleError} />
                                                    </div>
                                                ) : (
                                                    <div className="placeholder-box" />
                                                    // <Icon icon={'arcticons:scanner'} width="300" height="300" />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </Box>
                        </CardContent>
                    )}

                    {
                        secureLocalStorage.getItem("roleId") === "4" && (
                            <CardContent>
                                <Box display="flex" flexDirection="column" justifyContent="center">
                                    <Box>
                                        <Grid container spacing={2}>
                                            {/* Search Input */}
                                            <Grid item xs={12} md={6} lg={4} style={{ marginLeft: '70px', marginTop: '50px' }}>
                                                <TextField
                                                    label="Enter Device ID"
                                                    variant="outlined"
                                                    value={searchQuery}
                                                    onChange={handleSearchQueryChange}
                                                    fullWidth
                                                />
                                                {showSuggestions && filteredDeviceDetails.length > 0 && (
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={handleClickAway}>
                                                            <MenuList>
                                                                {filteredDeviceDetails.map(deviceDetail => (
                                                                    <MenuItem key={deviceDetail} onClick={() => handleMenuItemClick(deviceDetail)}>
                                                                        {deviceDetail}
                                                                    </MenuItem>
                                                                ))}
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                )}
                                            </Grid>
                                            {/* Search Button */}
                                            <Grid item xs={12} md={6} lg={4} style={{ marginLeft: '30px', marginTop: '50px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onMouseDown={handleMouseDown}
                                                    onClick={handleSearch}
                                                    sx={{ fontSize: '1.2rem', padding: '10px 20px' }}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                            {/* Display Device Details or No Details Message */}
                                            {searched && Object.keys(devicesDetails).length == 0 && (
                                                <Grid item xs={12} lg={8} style={{ marginTop: '25px' }}>
                                                    <Typography variant="body1" style={{ marginBottom: '10px', fontWeight: 'bold', marginLeft: '300px' }} >No details present</Typography>
                                                </Grid>
                                            )}
                                            {Object.keys(devicesDetails).length > 0 && (
                                                <Grid container spacing={6} justify="space-between">
                                                    <Grid item xs={12} lg={8} style={{ marginTop: '35px', marginLeft: '100px' }}>
                                                        {/* <Typography variant="h6" style={{ marginBottom: '10px' }}>Device Details:</Typography> */}
                                                        <Typography variant="body1" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                                                            Device Name: <span style={{ fontWeight: 'bold', color: 'green' }}>{devicesDetails.devicename}</span>
                                                        </Typography>
                                                        <Typography variant="body1" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                                                            OS Version: <span style={{ fontWeight: 'bold', color: 'green' }}>{devicesDetails.osversion}</span>
                                                        </Typography>
                                                        {/* Add more details here */}


                                                    </Grid>
                                                    {/* Button for Generating QR Code */}

                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        flexDirection="column"
                                                        style={{
                                                            marginTop: '80px',
                                                            marginBottom: '50px',
                                                            paddingRight: '20px', // Add padding to the right to account for the console
                                                            boxSizing: 'border-box',
                                                            width: '100%', // Ensure the Box takes the full width of the viewport
                                                            overflow: 'hidden' // Prevent overflow
                                                        }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={6}
                                                            lg={4}
                                                            style={{
                                                                maxWidth: '100%',
                                                                padding: '0 10px', // Optional: padding to prevent content touching screen edges
                                                                marginLeft: '100px', // Move the Grid item slightly to the right
                                                                marginRight: '20px' // Additional margin to ensure it's not cut off
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onMouseDown={handleMouseDown}
                                                                onClick={() => handleGenerateQRCode(devicesDetails.randomDeviceId)}
                                                                sx={{ fontSize: '1rem', padding: '8px 15px' }}
                                                            >
                                                                Generate QRCode
                                                            </Button>
                                                        </Grid>
                                                    </Box>



                                                </Grid>
                                            )}
                                        </Grid>

                                    </Box>

                                </Box>
                            </CardContent>
                        )
                    }

                </Card>
            </Container>

            <div>
                <Dialog
                    open={openPopup}
                    onClose={handleClosePopup}
                    maxWidth="md"
                    fullWidth
                    sx={{ width: '90%' }}
                >
                    <DialogContent>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                            <Box>
                                <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Box textAlign="center" marginBottom="10px">
                                            <QRCode value={JSON.stringify(apiResponse)} size={220} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={8}>
                                        <Grid container spacing={2} direction="column">
                                            <Box textAlign="left" maxWidth="400px">
                                                <Typography variant="h4" mt={2} mb={3}>
                                                    Scan QR Code
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                                                    1. Open iBsm App on your Device
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                                                    2. Point your Device to QR code on the Screen
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                                                    3. Scan QR code to Enrol the Device
                                                </Typography>

                                                <Typography variant="body1" sx={{ color: 'green' }}>
                                                    {/* Additional content */}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePopup}
                            onMouseDown={handleMouseDown}
                            color="primary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default AddDevice
