import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { setUserData } from '../../store';
import './style.css';

// @mui-components
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Container,
    CssBaseline,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

// hooks
import useResponsive from '../../hooks/useResponsive';

//components
import Iconify from "../../components/iconify";
import Logo from '../../components/logo';
//internals
import LoginImg from "../../assets/illustrations/medicine.svg";

//services
import ApiCalls from "../../services/try";

//externals
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { GoogleLogin } from 'react-google-login';
import secureLocalStorage from "react-secure-storage";
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

const responseGoogle = (response) => {
    console.log(response);
}

const onFailure = (error) => {
    console.log(error);
}

const GoogleLoginButton = () => (
    <GoogleLogin
        clientId="YOUR_GOOGLE_API_CLIENT_ID"
        onSuccess={responseGoogle}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        buttonText="Sign in with Google"
    />
);

// ----------------------------------------------------------------------

const Login = () => {

    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [wizardenable, setWizardenable] = useState(false);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case 'email':
                    setEmailError(true);
                    break;
                case 'password':
                    setPasswordError(true);
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'email':
                setEmailError(false);
                setEmail(event.target.value);
                break;
            case 'password':
                setPasswordError(false);
                setPassword(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleClick = () => {
        // const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailOrPhoneNumberRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^\+\d{12}$/;

        if (!email) {
            setEmailError(true);
        } else if (!emailOrPhoneNumberRegex.test(email)) {
            setEmailError(true);
        } else if (!password) {
            setPasswordError(true);
        } else {
            var rolename = "";

            var data = { userName: email, password: password }

            ApiCalls.register("signin", data)
                .then(function (respone) {
                    secureLocalStorage.setItem('sessionId', respone.data.tokenId);
                    let User = respone.data.data.user;

                    dispatch(setUserData(User));

                    secureLocalStorage.setItem('roleId', User.roleid);
                    secureLocalStorage.setItem('rolename', User.rolename);

                    secureLocalStorage.setItem('UUID', User.uuid);
                    secureLocalStorage.setItem('tenantMailId', User.adminUser);
                    secureLocalStorage.setItem('userid', User.username);
                    secureLocalStorage.setItem('userMailId', User.username);
                    secureLocalStorage.setItem('ismenuloaded', false);
                    secureLocalStorage.setItem('menu', []);
                    secureLocalStorage.setItem('isSignedIn', true);

                    rolename = User.rolename;

                    if (rolename === "Tenant") {
                        secureLocalStorage.setItem('tenantId', User.tenantId);
                        secureLocalStorage.setItem('compId', 0);
                        secureLocalStorage.setItem('companyId', 0);
                        secureLocalStorage.setItem('physicianId', 0);
                        secureLocalStorage.setItem('patientId', 0);
                    } else if (rolename === "Company") {
                        secureLocalStorage.setItem('tenantId', User.tenantId);
                        secureLocalStorage.setItem('companyId', User.companyId);
                        secureLocalStorage.setItem('compId', User.uuid);
                        secureLocalStorage.setItem('physicianId', 0);
                        secureLocalStorage.setItem('patientId', 0);
                    } else if (rolename === "Physician") {
                        secureLocalStorage.setItem('tenantId', User.tenantId);
                        secureLocalStorage.setItem('companyId', User.companyId);
                        secureLocalStorage.setItem('compId', User.companyId);
                        secureLocalStorage.setItem('physicianId', User.physicianId);
                        secureLocalStorage.setItem('patientId', 0);
                    } else if (rolename === "Patient") {
                        secureLocalStorage.setItem('tenantId', User.tenantId);
                        secureLocalStorage.setItem('compId', User.companyId);
                        secureLocalStorage.setItem('companyId', User.companyId);
                        secureLocalStorage.setItem('physicianId', User.physicianId);
                        secureLocalStorage.setItem('patientId', User.patientId);
                    }
                    ApiCalls.getRoleBasedMenu(User.adminUser, User.roleid)
                        .then(function (response) {
                            if (response.data.length > 0) {
                                setRedirectToReferrer(true);
                            } else {
                                setWizardenable(true);
                                setRedirectToReferrer(true);
                            }
                        })
                    ApiCalls.getdetails("ithings/getUserRolelist/" + User.adminUser)
                        .then(function (res) {
                            if (res.data.length > 0) {
                                for (let i = 0; i < res.data.length; i++) {
                                    const role = res.data[i];
                                    let keyName;

                                    switch (role.roleId) {
                                        case 3:
                                            keyName = "TenantName";
                                            break;
                                        case 4:
                                            keyName = "CompanyName";
                                            break;
                                        case 6:
                                            keyName = "PhysicianName";
                                            break;
                                        case 8:
                                            keyName = "PatientName";
                                            break;
                                        case 9:
                                            keyName = "DeviceName";
                                            break;
                                        default:
                                            continue; // Skip roles that don't have a matching key name
                                    }
                                    secureLocalStorage.setItem(keyName, role.customRoleName);
                                }
                            } else {
                                secureLocalStorage.setItem('TenantName', "Tenant");
                                secureLocalStorage.setItem('CompanyName', "Company");
                                secureLocalStorage.setItem('PhysicianName', "Physician");
                                secureLocalStorage.setItem('PatientName', "Patient");
                                secureLocalStorage.setItem('DeviceName', "Device");
                            }
                        })
                    ApiCalls.getdetails(+ User.roleid + "/" + User.uuid + "/getdettt")
                        .then(function (response) {
                            if (User.roleid == 4) {
                                secureLocalStorage.setItem('LoginUserName', response.data.userSchemas[0].firstName);
                            } else {
                                secureLocalStorage.setItem('LoginUserName', response.data.userSchemas[0].firstName + " " + response.data.userSchemas[0].lastName);
                            }
                        })
                })
                .catch((error) => {
                    swal("Invalid Username or Password", {
                        icon: "error",
                    });
                })
            // For example, to navigate to another page using React Router:
        }

    };

    const mdUp = useResponsive('up', 'md');

    const rname = secureLocalStorage.getItem("rolename");

    if ((redirectToReferrer) && (rname === "Physician")) {
        // return(<Redirect to = {'/Patientlist'}/>);
        return (<Redirect to={'/dashboard'} />);
    }
    if ((redirectToReferrer) && (rname === "Patient")) {
        return (<Redirect to={'/mydashboard'} />)
    }
    if ((redirectToReferrer) && (rname === "Tenant") && (wizardenable)) {
        return (<Redirect to={'/wizardsetup'} />)
    }

    if ((redirectToReferrer) && (rname === "Tenant")) {
        return (<Redirect to={'/dashboard'} />)
    }

    if ((redirectToReferrer) && (rname === "Company")) {
        return (<Redirect to={'/dashboard'} />)
    }
    if ((redirectToReferrer)) {
        return (<Redirect to={'/wizardsetup'} />)
    }

    return (
        <div class="container">
            <div class="forms-container">
                <div class="signin-signup">
                    <Grid container component="main" sx={{ height: '100vh' }}>
                        <CssBaseline />
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Logo
                                sx={{
                                    position: 'fixed',
                                    top: { xs: 16, sm: 24, md: 50 },
                                    right: { xs: 16, sm: 24, md: 60 },
                                }}
                            />
                        </Box>

                        <Container maxWidth="sm">
                            <StyledContent>
                                {/* <Button
                                    variant='outlined'
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    <img
                                        src="https://developers.google.com/identity/images/g-logo.png"
                                        alt="Google logo"
                                        style={{ marginRight: '10px', width: '20px', height: '20px' }}
                                    />
                                    Sign in with Google
                                </Button> */}
                                {/* <Divider sx={{ my: 3 }}>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        OR
                                    </Typography>
                                </Divider> */}

                                {/* <LoginForm /> */}

                                <Stack spacing={3}>

                                    <TextField
                                        name="email"
                                        label="Username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        autoFocus={true}
                                        autoComplete="off"
                                        error={emailError}
                                        helperText={emailError && "Username is required"}
                                    />

                                    <TextField
                                        name="password"
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={passwordError}
                                        helperText={passwordError && "Password is required"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton color="primary" onClick={() => setShowPassword(!showPassword)} onMouseDown={(e) => e.preventDefault()} edge="end">
                                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>

                                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                    <Link to={"/forgotPassword"}>
                                        Forgot password?
                                    </Link>
                                </Stack>

                                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                                    Login
                                </LoadingButton>

                            </StyledContent>
                        </Container>
                    </Grid>
                </div>
            </div>

            <div class="panels-container">
                <div class="panel left-panel">
                    <div class="content">
                        <h3>
                            Sign in to IoMT
                        </h3>
                        <p>
                            Don’t have an account?
                        </p>
                        <Link to="/SignUp">
                            <button class="btn transparent" id="sign-up-btn">
                                Sign up
                            </button>
                        </Link>
                    </div>
                    <img src={LoginImg} class="image" alt="" />
                </div>
            </div>

        </div>
    );
};

export default Login;
